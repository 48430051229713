const urlToTime = {};
export function getMediaDuration(url, isVideo = true) {
    return new Promise((resolve, reject) => {
        if (urlToTime[url]) {
            return resolve(urlToTime[url]);
        }
        const mediaElement = document.createElement(isVideo ? "video" : "audio");
        mediaElement.addEventListener("loadedmetadata", function () {
            urlToTime[url] = mediaElement.duration;
            resolve(mediaElement.duration);
        });
        mediaElement.src = url;
    });
}
export function uuid() {
    const tempUrl = URL.createObjectURL(new Blob());
    const uuid = tempUrl.toString();
    URL.revokeObjectURL(tempUrl); // 释放这个url
    return uuid.substring(uuid.lastIndexOf("/") + 1);
}
export function findInterval(value, intervals) {
    // 排序区间
    intervals.sort((a, b) => a[0] - b[0]);
    // 合并区间
    let mergedIntervals = [];
    for (let interval of intervals) {
        if (!mergedIntervals.length || mergedIntervals[mergedIntervals.length - 1][1] < interval[0] - 1) {
            mergedIntervals.push(interval);
        }
        else {
            mergedIntervals[mergedIntervals.length - 1][1] = Math.max(mergedIntervals[mergedIntervals.length - 1][1], interval[1]);
        }
    }
    // 检查当前值
    for (let [start, end] of mergedIntervals) {
        if (value >= start && value <= end) {
            if (value == start) {
                return "start";
            }
            else if (value == end) {
                return "end";
            }
            else {
                return "in";
            }
        }
    }
}
// 防抖
export function debounce(fn, delay) {
    let timer;
    return function (...args) {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            fn(...args);
        }, delay);
    };
}
/**
 * 生成不相等的“伪 UUID 空白符”
 * @returns {string} 随机空白符
 */
export function generateBlank() {
    const baseBlanks = [
        "\u0020", // 空格
        "\u0009", // 水平制表符
        "\u00A0", // 不间断空格
        "\u2003", // em空格
        "\u2002", // en空格
        "\u2009", // 窄空格
        "\u200C", // 零宽非连接符
        "\u200D", // 零宽连接符
        "\u200B", // 零宽空格
    ];
    const separators = ["\u200B", "\u200C", "\u200D"];
    let pseudoUUID = "";
    for (let part = 0; part < 8; part++) {
        // 增加部分数量至 8
        const partLength = Math.floor(Math.random() * 8) + 1; // 每部分长度 1 至 8
        const segment = new Array(partLength)
            .fill(null)
            .map(() => baseBlanks[Math.floor(Math.random() * baseBlanks.length)])
            .join("");
        pseudoUUID += segment;
        if (part < 7) {
            pseudoUUID += separators[Math.floor(Math.random() * separators.length)];
        }
    }
    return pseudoUUID;
}
export function customTrim(string) {
    if (typeof string != 'string')
        return string;
    // 此正则表达式包括零宽空格(U+200B)，零宽非连接符(U+200C)和零宽连接符(U+200D)
    return string.replace(/[\u200B\u200C\u200D]+/g, '').trim();
}
// 替换文本中零宽字符和空格和换行符号
export function replaceBlank(str) {
    if (typeof str !== 'string')
        return str;
    return str.replace(/[\u200B\u200C\u200D\u0020\u0009\u000A\u000D]+/g, '');
}
export function replaceTextTag(strText) {
    const regex = /\[p\d+\]/g;
    const regexEmoji = /\[emoji\d+\]/g;
    const regexAction = /\[action\d+\]/g;
    let str = strText || '';
    if (regex.test(strText)) {
        str = str.replace(regex, (match, v) => {
            return '';
        });
    }
    if (regexEmoji.test(strText)) {
        str = str.replace(regexEmoji, (match, v) => {
            return '';
        });
    }
    if (regexAction.test(strText)) {
        str = str.replace(regexAction, (match, v) => {
            return '';
        });
    }
    return str;
}
