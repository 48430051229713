import Txt from "@/assets/images/timeLIne/Txt.svg";
import { uuid } from "@/lib/Utils/common";
import { doItemsOverlap } from "../utils/common";
export class TimelineRow {
    id;
    type;
    y;
    height;
    totalFrame = 0;
    pixelsPerFrame = 0;
    items;
    icon = Txt;
    activeIcon = Txt;
    rowIcons = null;
    txtColor = "#000000";
    bcColor = "#e6e6fb";
    muted = false;
    showDraw = true;
    name;
    parent;
    children = [];
    interval = [-1, -1];
    constructor(y = 0, height = 0) {
        this.id = uuid();
        this.y = y;
        this.height = height;
        this.items = [];
    }
    addItem(item) {
        if (this.interval[0] != -1 && this.interval[1] != -1 && item.startTime > this.interval[1]) {
            item.startTime = this.interval[0];
        }
        const isOverlap = this.items.filter(i => !i.isDelete).filter((i) => doItemsOverlap(i, item)).length > 0;
        if (isOverlap) {
            item.startTime = this.items.filter(i => !i.isDelete).reduce((acc, i) => {
                if (i.getEndTime() > acc) {
                    return i.getEndTime();
                }
                return acc;
            }, 0);
        }
        if (this.interval[1] != -1 && this.interval[1] < item.getEndTime() && this.parent) {
            const parentRowLastItem = this.parent.items[this.parent.items.length - 1];
            parentRowLastItem.time = item.getEndTime() - parentRowLastItem.startTime;
            this.updateInterval();
        }
        item.timeLineRow = this;
        item.rowId = this.id;
        this.items.push(item);
    }
    clone() {
        const cloneRow = new TimelineRow();
        cloneRow.bcColor = this.bcColor;
        cloneRow.pixelsPerFrame = this.pixelsPerFrame;
        cloneRow.icon = this.icon;
        cloneRow.activeIcon = this.activeIcon;
        cloneRow.txtColor = this.txtColor;
        cloneRow.bcColor = this.bcColor;
        cloneRow.muted = this.muted;
        cloneRow.showDraw = this.showDraw;
        cloneRow.name = this.name;
        return cloneRow;
    }
    getBox() {
        const startTime = this.items.sort((a, b) => a.startTime - b.startTime)[0].startTime;
        const endTime = this.items.sort((a, b) => b.getEndTime() - a.getEndTime())[0].getEndTime();
        const time = endTime - startTime;
        return {
            startTime,
            endTime,
            time,
        };
    }
    addChild(item) {
        if (!this.children.includes(item)) {
            this.children.push(item);
        }
    }
    setParent(row) {
        this.parent = row;
        this.parent?.addChild(this);
        this.updateInterval();
    }
    // 用于重新排列items
    rearrangeItems() {
        if (this.items.length < 2)
            return;
        const items = this.items.filter((item) => !item.isDelete);
        items.sort((a, b) => a.startTime - b.startTime);
        for (let i = 1; i < items.length; i++) {
            const prevItem = items[i - 1];
            const currItem = items[i];
            if (doItemsOverlap(prevItem, currItem)) {
                currItem.startTime = prevItem.getEndTime();
            }
        }
    }
    updateInterval() {
        const interval = [-1, -1];
        this.parent?.items.forEach((item) => {
            if (interval[0] == -1 || interval[0] > item.startTime) {
                interval[0] = item.startTime;
            }
            if (interval[1] == -1 || interval[1] < item.getEndTime()) {
                interval[1] = item.getEndTime();
            }
        });
        this.interval = interval;
        this.items.forEach((item) => {
            item.interval = this.interval;
        });
    }
    draw(ctx) {
        // this.updateInterval();
        let selectedItems = [];
        this.items.forEach((item) => {
            item.row = this.items;
            item.type = this.type;
            item.height = this.height;
            item.y = this.y;
            item.txtColor = this.txtColor;
            item.bcColor = this.bcColor;
            item.totalFrame = this.totalFrame;
            item.pixelsPerFrame = this.pixelsPerFrame;
            item.muted = this.muted;
            item.rowId = this.id;
            item.showDraw = this.showDraw;
            item.interval = this.interval;
            item.icon = this.icon;
            item.activeIcon = this.activeIcon;
            if (!item.isSelected) {
                item.draw(ctx);
            }
            else {
                selectedItems.push(item);
            }
        });
        selectedItems.forEach((item) => {
            item.draw(ctx);
        });
    }
}
