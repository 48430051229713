// import CanvasDraw from "./canvasDraw";
import { getVectorLenth, uuid } from "../utils/common";
// 绘制的骨架，当选中某一个图形的时候，会出现外层的骨架。( 这个单独创建一个类 ）
export class Border {
    canvas;
    newFramework;
    point;
    c_point = [];
    r_point = [];
    hiddenShowR_point = false;
    hiddenShowC_point = false;
    hiddenShowS_point = false;
    textItemType = ['caption', 'caption-ppt'];
    lineWidth = 4; // 四边线宽
    p_width = [16, 10, 24]; // 四角点位 【白底大小， 蓝色大小，触发区域范围大小】
    c_width = [20, 16, 20]; // 四边中心点位  【白底大小， 蓝色大小，触发区域范围大小】
    r_width = [16, 8, 15]; // 四角点位 【白底大小， 图标半径大小，触发区域范围大小】
    constructor(canvas) {
        this.canvas = canvas;
    }
    refresh(newFramework) {
        const { hiddenShowR_point, hiddenShowC_point, hiddenShowS_point } = newFramework;
        this.hiddenShowR_point = hiddenShowR_point;
        this.hiddenShowC_point = hiddenShowC_point;
        this.hiddenShowS_point = hiddenShowS_point;
        this.newFramework = newFramework;
        this.point = this.newFramework.point;
        // 中点
        this.c_point = [];
        if (!hiddenShowC_point) {
            this.point.reduce((a, b) => {
                this.c_point.push([(a[0] + b[0]) / 2, (a[1] + b[1]) / 2]);
                return b;
            }, this.point[3]);
        }
        if (!hiddenShowR_point) {
            // 旋转点
            this.r_point = [
                (this.point[2][0] + this.point[3][0]) / 2,
                this.point[2][1] + 35,
            ];
        }
        this.draw();
    }
    draw() {
        const { point, center, angle, width, height, itemType } = this.newFramework;
        const { ctx: context } = this.canvas;
        // console.log('context', this.canvas)
        const [c_x, c_y] = center;
        const points = point;
        context.save();
        context.translate(c_x, c_y);
        context.rotate(angle);
        context.beginPath();
        context.lineWidth = this.lineWidth;
        context.strokeStyle = "#3474D7";
        context.rect(points[0][0] - c_x, points[0][1] - c_y, width, height);
        context.stroke();
        context.closePath();
        const pointList = points.concat(this.c_point);
        pointList.push(this.r_point);
        /**
         * item
         * 0-3 是 四角的点
         * 4-7 是中心点
         * 8 是旋转点
         */
        pointList.forEach((item, index) => {
            const [x, y] = item;
            // 如果是 text 不可以横/纵向缩放，不渲染点
            // 四角位置点 
            // if (index < 4 && !this.textItemType.includes(itemType)) {
            if (index < 4) {
                context.beginPath();
                context.fillStyle = "#ffffff";
                const circleOut = context.arc(x - 0 - c_x, y - 0 - c_y, this.p_width[0], 0, 100);
                context.fill(circleOut);
                context.beginPath();
                context.fillStyle = "#3474D7";
                const circle = context.arc(x - 0 - c_x, y - 0 - c_y, this.p_width[1], 0, 100);
                context.fill(circle);
                //是否不展示 中心点，如果展示 旋转点为  第4个为旋转点 ，有中心点 第8个位旋转点
            }
            else if (this.hiddenShowC_point ? index === 4 : index === 8) {
                // 判断是否展示 旋转点，
                if (!this.hiddenShowR_point) {
                    // context.save();
                    context.lineWidth = 2;
                    context.beginPath();
                    context.fillStyle = "#ffffff";
                    const circle = context.arc(x - 0 - c_x, y - 0 - c_y, this.r_width[0], 0, 100);
                    context.fill(circle);
                    context.beginPath();
                    context.strokeStyle = "#43465e";
                    context.fillStyle = "#43465e";
                    const circle1 = context.arc(x - 0 - c_x, y - 0 - c_y, this.r_width[1], 0, Math.PI * 1.65);
                    context.stroke();
                    context.beginPath();
                    context.moveTo(x - 0 - c_x + 3, y - 0 - c_y);
                    context.lineTo(x - 0 - c_x + 7.5, y - 0 - c_y - 1);
                    context.lineTo(x - 0 - c_x + 4.5, y - 0 - c_y - 3);
                    context.lineTo(x - 0 - c_x + 2.5, y - 0 - c_y + 0.5);
                    context.fill();
                    context.stroke();
                }
            }
            else {
                // if ( itemType === "txt" || this.textItemType.includes(itemType)) {
                //   return
                // }
                // 隐藏中心点
                if (this.hiddenShowC_point) {
                    return;
                }
                context.lineCap = 'round';
                context.lineWidth = 8;
                context.strokeStyle = "#3474D7";
                context.fillStyle = "#ffffff";
                if (index === 4 || index === 6) {
                    context.beginPath();
                    context.moveTo(x - 0 - c_x, y - 0 - c_y - this.c_width[0]);
                    context.lineTo(x - 0 - c_x, y - 0 - c_y + this.c_width[0]);
                    context.stroke();
                    context.beginPath();
                    context.lineWidth = 5;
                    context.strokeStyle = "#ffffff";
                    context.moveTo(x - 0 - c_x, y - 0 - c_y - this.c_width[1]);
                    context.lineTo(x - 0 - c_x, y - 0 - c_y + this.c_width[1]);
                    context.stroke();
                }
                if (index === 5 || index === 7) {
                    context.beginPath();
                    context.moveTo(x - 0 - c_x - this.c_width[0], y - 0 - c_y);
                    context.lineTo(x - 0 - c_x + this.c_width[0], y - 0 - c_y);
                    context.stroke();
                    context.beginPath();
                    context.lineWidth = 5;
                    context.strokeStyle = "#ffffff";
                    context.moveTo(x - 0 - c_x - this.c_width[1], y - 0 - c_y);
                    context.lineTo(x - 0 - c_x + this.c_width[1], y - 0 - c_y);
                    context.stroke();
                }
                // 原点
                // context.beginPath();
                // context.fillStyle = "#ffffff";
                // const circleOut = context.arc(x - 0 - c_x, y - 0 - c_y, 8, 0, 100);
                // context.fill(circleOut);
                // context.beginPath();
                // // context.strokeStyle = "#3474D7";
                // context.fillStyle = "#3474D7";
                // const circle = context.arc(x - 0 - c_x, y - 0 - c_y, 6, 0, 100);
                // context.fill(circle);
            }
        });
        context.restore();
    }
    isPointInSkeletion(point) {
        let status = null;
        if (!this.newFramework) {
            return status;
        }
        const [x, y] = point;
        const { angle, itemType } = this.newFramework;
        const r_point = this.newFramework.rotatePoint(this.r_point, angle);
        const d_point = this.point.map((item) => this.newFramework.rotatePoint(item, angle));
        const c_point = this.c_point.map((item) => this.newFramework.rotatePoint(item, angle));
        // 旋转点
        (() => {
            const [c_x, c_y] = r_point;
            if (Math.sqrt(Math.pow(c_x - x, 2) + Math.pow(c_y - y, 2)) < this.r_width[2]) {
                status = "r_point";
            }
        })();
        if (!this.hiddenShowS_point) {
            d_point.forEach((item, index) => {
                const [c_x, c_y] = item;
                if (Math.sqrt(Math.pow(c_x - x, 2) + Math.pow(c_y - y, 2)) < this.p_width[2]) {
                    status = `point_${index + 1}`;
                }
            });
        }
        // 如果是 text 不可以横/纵向缩放
        if (!this.hiddenShowC_point) {
            c_point.forEach((item, index) => {
                const [c_x, c_y] = item;
                if (Math.sqrt(Math.pow(c_x - x, 2) + Math.pow(c_y - y, 2)) < this.c_width[2]) {
                    status = `c_point_${index + 1}`;
                }
            });
        }
        return status;
    }
}
export class FrameWork {
    baseWH;
    height;
    width;
    center;
    angle;
    id;
    scalePercent = 1; // 一共缩放的比例
    parent = this;
    point;
    hiddenShowR_point = false;
    hiddenShowC_point = false;
    hiddenShowS_point = false;
    itemType; // 文字不展示四边中间的点；
    constructor(parms) {
        const { width, height, center, angle, point, baseWH, id } = parms;
        this.height = height;
        this.width = width;
        this.center = center;
        this.angle = angle;
        this.getPoint();
        // this.baseWH = [width, height];
        this.itemType = parms.itemType;
        this.hiddenShowR_point = typeof parms.hiddenShowR_point === 'boolean' ? parms.hiddenShowR_point : this.hiddenShowR_point;
        this.hiddenShowC_point = typeof parms.hiddenShowC_point === 'boolean' ? parms.hiddenShowC_point : this.hiddenShowC_point;
        this.hiddenShowS_point = typeof parms.hiddenShowS_point === 'boolean' ? parms.hiddenShowS_point : this.hiddenShowS_point;
        // 编辑模式
        this.point = point || this.point;
        this.baseWH = baseWH || [width, height];
        this.id = id || uuid();
    }
    updateXYAngle(params) {
        const { center, angle, width, height } = params;
        this.center = center ? center : this.center;
        this.angle = angle;
        this.width = typeof width !== undefined ? width : this.width;
        this.height = typeof height !== undefined ? height : this.height;
        this.baseWH = [this.width, this.height];
        this.getPoint();
    }
    //重新设置 基础宽高
    resetBaseWH() {
        this.baseWH = [this.width, this.height];
    }
    // 旋转
    rotate(angle) {
        this.angle = angle;
    }
    // 平移
    translate(vector) {
        const [_x, _y] = vector;
        const points = this.point.map((item) => [item[0] + _x, item[1] + _y]);
        this.point = points;
        this.getCenter();
    }
    // 缩放
    zoom(status, vector) {
        const _x = parseFloat(vector[0]);
        const _y = parseFloat(vector[1]);
        const _angle = this.angle;
        // 旋转后的 x 轴相对于 canvas 的位置
        let _x_x = Math.sin(_angle + Math.PI / 2);
        let _y_x = Math.cos(_angle + Math.PI / 2);
        // 如果角度为 0 特殊设置，因为有些 Math.cos 不兼容
        if (_angle === 0) {
            _x_x = 1;
            _y_x = 0;
        }
        // 移动向量 vector 在旋转后 x 轴的距离
        const n_x = getVectorLenth([_x_x, _y_x], [_x, -_y]);
        // 旋转后的 y 轴相对于 canvas 的位置
        const _x_y = Math.sin(_angle) * 5;
        const _y_y = Math.cos(_angle) * 5;
        // 移动向量 vector 在旋转后 y 轴的距离
        const n_y = getVectorLenth([_x_y, _y_y], [_x, -_y]);
        // 通过正切计算出顶点的角度
        const tan = Math.atan(this.height / this.width);
        const pointZoom = (_angles) => {
            // 获取在第一个顶点上面的移动距离
            const n_tan = getVectorLenth([-Math.cos(_angles), Math.sin(_angles)], [_x, -_y]);
            this.width += n_tan * Math.cos(tan);
            this.height += n_tan * Math.sin(tan);
            this.center = [
                this.center[0] - (n_tan * Math.cos(_angles)) / 2,
                this.center[1] - (n_tan * Math.sin(_angles)) / 2,
            ];
        };
        if (status === "point_1") {
            // 第1个顶点
            const _angles = tan + _angle;
            pointZoom(_angles);
        }
        else if (status === "point_2") {
            // 第2个顶点
            const _angles = Math.PI - tan + _angle;
            pointZoom(_angles);
        }
        else if (status === "point_3") {
            // 第3个点
            const _angles = Math.PI + tan + _angle;
            pointZoom(_angles);
        }
        else if (status === "point_4") {
            // 第4个点
            const _angles = 2 * Math.PI - tan + _angle;
            pointZoom(_angles);
        }
        else if (status === "c_point_1") {
            this.width -= n_x;
            this.center = [
                this.center[0] + (n_x * Math.cos(_angle)) / 2,
                this.center[1] + (n_x * Math.sin(_angle)) / 2,
            ];
        }
        else if (status === "c_point_2") {
            this.height += n_y;
            this.center = [
                this.center[0] + (n_y * Math.sin(_angle)) / 2,
                this.center[1] - (n_y * Math.cos(_angle)) / 2,
            ];
        }
        else if (status === "c_point_3") {
            this.width += n_x;
            this.center = [
                this.center[0] + (n_x * Math.cos(_angle)) / 2,
                this.center[1] + (n_x * Math.sin(_angle)) / 2,
            ];
        }
        else if (status === "c_point_4") {
            this.height -= n_y;
            this.center = [
                this.center[0] + (n_y * Math.sin(_angle)) / 2,
                this.center[1] - (n_y * Math.cos(_angle)) / 2,
            ];
        }
        this.getPoint();
        this.setWH();
        this.resetBaseWH();
    }
    // 确定定是否内部
    isPointInRect(point) {
        // 旋转后的位置
        const points = this.point.map((item) => this.rotatePoint(item, this.angle));
        const p1 = points[0];
        const p2 = points[1];
        const p3 = points[2];
        const p4 = points[3];
        const x = point[0];
        const y = -point[1];
        // 90° 有问题单独处理了。
        if (Math.abs(this.angle).toFixed(2) === '1.57') {
            const p01 = this.point[0];
            const p03 = this.point[2];
            return point[0] >= p01[0] && point[0] <= p03[0] && point[1] >= p01[1] && point[1] <= p03[1];
        }
        // 是否在两个平行线内
        const ratio1 = (function () {
            const a = (p1[1] - p2[1]) / (p2[0] - p1[0]);
            const b = -p1[1] - a * p1[0];
            const c = -p3[1] - a * p3[0];
            if (b > c && a * x + b > y && a * x + c < y)
                return true;
            if (b < c && a * x + b < y && a * x + c > y)
                return true;
            return false;
        })();
        // 是否在两个平行线内
        const ratio2 = (function () {
            const a = (p2[1] - p3[1]) / (p3[0] - p2[0]);
            const b = -p2[1] - a * p2[0];
            const c = -p4[1] - a * p4[0];
            if (p3[0] - p2[0] === 0) {
                if ((p1[0] < x && x < p2[0]) || (p1[0] > x && x > p2[0]))
                    return true;
            }
            if (b > c && a * x + b > y && a * x + c < y)
                return true;
            if (b < c && a * x + b < y && a * x + c > y)
                return true;
            return false;
        })();
        if (ratio1 && ratio2)
            return true;
        return false;
    }
    // 获取中点
    getCenter() {
        const p1 = this.point[0];
        const p3 = this.point[2];
        const x = p1[0] + p3[0];
        const y = p1[1] + p3[1];
        this.center = [x / 2, y / 2];
    }
    // 某点绕中心点旋转角度
    rotatePoint(point, angle) {
        const [x, y] = point;
        const [c_x, c_y] = this.center;
        const _x = (x - c_x) * Math.cos(angle) - (y - c_y) * Math.sin(angle) + c_x;
        const _y = (x - c_x) * Math.sin(angle) + (y - c_y) * Math.cos(angle) + c_y;
        return [_x, _y];
    }
    // 通过宽高和中点获取四个点 (根据功能性拆分函数)
    getPoint() {
        const h = this.height;
        const w = this.width;
        const [c_x, c_y] = this.center;
        const points = [];
        points[0] = [c_x - w / 2, c_y - h / 2];
        points[1] = [c_x + w / 2, c_y - h / 2];
        points[2] = [c_x + w / 2, c_y + h / 2];
        points[3] = [c_x - w / 2, c_y + h / 2];
        this.point = points;
    }
    setWH() {
        this.height = Math.abs(this.point[0][1] - this.point[3][1]);
        this.width = Math.abs(this.point[0][0] - this.point[1][0]);
    }
}
