import VolumeIcon from "@/assets/images/timeLIne/Volume.svg";
import MuteIcon from "@/assets/images/timeLIne/Mute.svg";
import HideIcon from "@/assets/images/timeLIne/Hide.svg";
import BrowseIcon from "@/assets/images/timeLIne/Browse.svg";
import DirectionLeftNone from "@/assets/images/timeline/DirectionLeftNone.svg";
import DirectionLeft from "@/assets/images/timeline/DirectionLeft.svg";
import DirectionRight from "@/assets/images/timeline/DirectionRight.svg";
import DirectionRightNone from "@/assets/images/timeline/DirectionRightNone.svg";
import { debounce } from "@/lib/Utils/common";
import { framesToTimecode, getTimeScaleType, getFormatFrameF, findDivisors, findIntervalAdjusted, } from "../utils/common";
import { template } from "../utils/template";
import { CustomSlider } from "./CustomSlider";
import { EVENT_MAP, doItemsOverlap } from "../utils/common";
import EventEmitter from "../../Utils/EventEmitter";
import { TimelineRow } from "./TimelineRow";
import { TimelineItem } from "./TimelineItem";
export class Timeline extends EventEmitter {
    static EVENT_MAP = EVENT_MAP;
    rows = [];
    animationid = null;
    isDown = false;
    isMove = false;
    // 鼠标位置类型 none: 无效, timeLine: 时间线, timeLineItem: 时间线上的元素, timeLineItemLeftHandle: 时间线上的元素左边的手柄, timeLineItemRightHandle: 时间线上的元素右边的手柄
    mousePositinTypeObj = {
        type: "none",
        isTimelineItemChange: false,
        firstTimeLineItemSelect: false,
        data: null,
    };
    defaultConfig = {
        id: "timeline-container",
        totalFrame: 0,
        fps: 30,
        playLoop: true,
        historyEnable: true,
        overviewEnable: true,
        rowBtnsEnable: true,
    };
    config;
    history = null;
    timelineContainer;
    canvasContainer;
    canvas;
    ctx;
    scrollbar;
    scrollbarContainer;
    timelineRowIcons;
    zoomSlider;
    timeLineSliderContainer;
    zoomSliderPlus;
    zoomSliderMinus;
    zoomSliderQuanLan;
    captionSwitch;
    timeLineSlider;
    timeLineDirection;
    timeLineDirectionLeft;
    timeLineDirectionRight;
    timeLineSliderTimes;
    dpr;
    padding = { top: 4, right: 10, bottom: 10, left: 10 };
    basePixelsPerFrame = 80;
    totalFrame;
    currentTime = 0;
    fps = 30;
    fpsDivisors = [];
    _previousTime = 0;
    pixelsPerFrame = 80;
    scale = 1;
    offsetY = 0;
    offsetX = 0;
    selectedItem = [];
    dragStartX = 0;
    dragStartY = 0;
    totalWidth = 0;
    totalHeight = 0;
    timelineRowHeight = 30;
    timelineRowMarginBottom = 8;
    timelineMarginTop = 30;
    autoScrollInterval = null;
    playingStartTime = 0;
    playingStartNow = 0;
    alignmentLineColor = "#9747FF"; // 吸附阈值
    alignmentLineDash = [3, 3]; // 吸附阈值
    alignmentThreshold = 5; // 吸附阈值
    alignmentLineTime = null; // 对齐虚线的位置
    alignmentType = null; // 对齐类型 start: 左对齐 end: 右对齐
    playing = false;
    isScrollY = true;
    constructor(config = {
        id: "timeline-container",
        totalFrame: 50,
        fps: 30,
    }) {
        super();
        this.config = Object.assign({}, this.defaultConfig, config);
        this.totalFrame = this.config.totalFrame;
        this.fps = this.config.fps;
        this.fpsDivisors = findDivisors(this.fps);
        this.updateDpr();
        this.on(EVENT_MAP.onTimelineItemChange, () => {
            this.rows.forEach((row) => {
                const parentRow = this.rows.find((r) => r.id == row.parentId);
                parentRow && row.updateInterval();
            });
        });
    }
    /**
     * 初始化时间线
     */
    init() {
        const container = document.getElementById(this.config.id);
        if (!container)
            throw new Error("this.config.id 不存在");
        const { timelineContainer, canvasContainer, canvas, scrollbar, scrollbarContainer, zoomSliderContainer, zoomSliderPlus, zoomSliderMinus, zoomSliderQuanLan, captionSwitch, timeLineSlider, timeLineSliderContainer, timeLineSliderTimes, timeLineDirection, timeLineDirectionLeft, timeLineDirectionRight, timelineRowIcons, } = this.createHtmlElement(container, template);
        this.timelineContainer = timelineContainer;
        this.canvasContainer = canvasContainer;
        this.canvas = canvas;
        this.ctx = canvas.getContext("2d");
        this.updateCanvasSize();
        this.scrollbar = scrollbar;
        this.scrollbarContainer = scrollbarContainer;
        this.timelineRowIcons = timelineRowIcons;
        this.zoomSliderPlus = zoomSliderPlus;
        this.zoomSliderMinus = zoomSliderMinus;
        this.zoomSliderQuanLan = zoomSliderQuanLan;
        this.captionSwitch = captionSwitch;
        this.timeLineSlider = timeLineSlider;
        this.timeLineDirection = timeLineDirection;
        this.timeLineDirectionLeft = timeLineDirectionLeft;
        this.timeLineDirectionRight = timeLineDirectionRight;
        this.timeLineSliderTimes = timeLineSliderTimes;
        if (!this.config.historyEnable) {
            this.timeLineDirection.style.display = "none";
        }
        if (!this.config.overviewEnable) {
            this.zoomSliderQuanLan.style.display = "none";
        }
        this.zoomSlider = new CustomSlider(zoomSliderContainer, {
            interval: [300, 1],
        });
        this.timeLineSliderContainer = new CustomSlider(timeLineSliderContainer);
        this.initEvents();
        this.initScrollbar();
        this.initZoomSlider();
        this.initCaptionSwitch();
        this.initTimeLineSliderContainer();
        this.updateScrollbar();
        this.setCurrentTime(this.currentTime);
        this.zoomSlider.setValue(28);
        this.startAnimation();
        this.emit(EVENT_MAP.onTimelineItemChange);
    }
    /**
     * 重新计算时间线的宽高，并完成所有的重绘
     */
    resize() {
        this.updateCanvasSize();
        this.formatRows();
        this.updateBrother();
        this.redraw();
        this.updateScrollbar();
        this.emit(EVENT_MAP.update, this.currentTime);
    }
    /**
     * 更新时间线的宽高
     */
    updateCanvasSize() {
        const container = document.getElementById(this.config.id);
        if (!container)
            return;
        const scrollbarWidth = 6;
        const iconWidth = 80;
        const dpr = this.dpr;
        this.canvas.width =
            (container.clientWidth - scrollbarWidth - iconWidth) * dpr;
        this.canvas.height =
            (this.canvasContainer.clientHeight - scrollbarWidth) * dpr;
        this.canvas.style.width = `calc(100% - ${scrollbarWidth + iconWidth}px)`;
        this.canvas.style.height = `calc(100% - ${scrollbarWidth}px)`;
        this.ctx.scale(dpr, dpr);
    }
    /**
     * 格式化时间线的行的图标
     **/
    formatRows() {
        this.rows = this.rows.filter((row) => row.items.length);
        for (let i = 0; i < this.timelineRowIcons.children.length; i++) {
            const rowIcon = this.timelineRowIcons.children[i];
            const rowId = rowIcon.getAttribute("data-row-id");
            const row = this.rows.find((r) => r.id == rowId);
            if (!row) {
                rowIcon.remove();
                i--;
            }
        }
        this.rows.forEach((row) => {
            const rowIcon = this.timelineRowIcons.querySelector(`[data-row-id="${row.id}"]`);
            if (!rowIcon) {
                const rowIcons = this.createRowIcon(row.icon, row.activeIcon, row);
                this.timelineRowIcons.appendChild(rowIcons);
            }
        });
    }
    /**
     * 设置当前时间
     * @param currentTime 要设置的帧数
     * @param isUpdate 是否强制更新
     */
    setCurrentTime(currentTime, isUpdate = false) {
        currentTime = Math.round(currentTime);
        if (this.currentTime === currentTime && !isUpdate)
            return;
        if (currentTime > this.totalFrame) {
            currentTime = this.totalFrame;
        }
        if (currentTime < 0) {
            currentTime = 0;
        }
        this.currentTime = currentTime;
        const timeLineSliderValue = this.totalFrame
            ? (this.currentTime / this.totalFrame) * 100
            : 0;
        this.timeLineSliderContainer.setValue(timeLineSliderValue);
        this.timeLineSliderTimes.innerText = `${framesToTimecode(this.currentTime)}/${framesToTimecode(this.totalFrame)}`;
        this.emit(EVENT_MAP.update, this.currentTime);
    }
    /**
     * 获取当前时间 - 秒
     * @returns 当前时间（秒）
     */
    getCurrentTime() {
        return this.currentTime / this.fps;
    }
    /**
     * 获取当前时间 - 帧
     * @returns 当前时间（帧）
     */
    getCurrentTimeF() {
        return this.currentTime;
    }
    /**
     * 设置时间线的总时间
     * @param totalTime 总时间（秒）
     * @param isUpdate 是否重绘时间线
     */
    setTotalTime(totalTime) {
        const totalFrame = Math.round(totalTime * this.fps);
        if (totalFrame === this.totalFrame)
            return;
        this.totalFrame = totalFrame;
        this.updateScrollbar();
        this.setCurrentTime(this.currentTime, true);
    }
    /**
     * 获取时间线的总时间
     * @returns 总时间（秒）
     */
    getTotalTime() {
        return this.totalFrame / this.fps;
    }
    /**
     * 获取一秒的帧数
     * @returns 一秒的帧数
     */
    getFps() {
        return this.fps;
    }
    /**
     * 获取在当前时间的lineItem
     * @returns ITimeLineItem[]
     */
    getCurrentTimeItems(time) {
        if (!(time || time === 0)) {
            time = this.currentTime;
        }
        return this.rows.reduce((items, row) => {
            row.items.forEach((item) => {
                const startTime = item.startTime;
                const endTime = item.startTime + item.time;
                if (startTime <= time && endTime > time) {
                    items.push(item);
                }
            });
            return items;
        }, []);
    }
    /**
     * 添加一行到时间线
     * @param row ITimelineRow
     */
    addRow(row, isEvent = true) {
        if (!this.rows.includes(row)) {
            // 查找同类型第一个row
            const sameTypeRow = this.rows.find((r) => r.type === row.type);
            let isOverlap = true;
            // 检测两个row中items是否有重叠
            if (sameTypeRow) {
                isOverlap = row.items.some((item) => sameTypeRow.items.some((sameItem) => {
                    return doItemsOverlap(item, sameItem);
                }));
            }
            if (!isOverlap) {
                row.items.forEach((item) => {
                    sameTypeRow.addItem(item);
                });
            }
            else {
                const rowIcons = this.createRowIcon(row.icon, row.activeIcon, row);
                this.timelineRowIcons?.appendChild(rowIcons);
                this.rows.unshift(row);
                row.items.forEach((item) => (item.icon = row.icon));
            }
        }
        // this.updateBrother();
        this.updateScrollbar();
        this.emit(EVENT_MAP.update, this.currentTime);
        if (isEvent) {
            setTimeout(() => {
                this.emit(EVENT_MAP.onTimelineItemChange);
            }, 500);
        }
    }
    /**
     * 根据id获取时间线的元素
     * @param id String 时间线元素的id
     * @returns timeLineItem ITimelineItem
     */
    getLineItemById(id) {
        let result;
        this.rows.forEach((row) => {
            row.items.forEach((item) => {
                if (item.id === id) {
                    result = item;
                }
            });
        });
        return result;
    }
    /**
     * 更新兄弟元素  ---   废弃
     */
    updateBrother() {
        const brotherIdMap = {};
        this.rows.forEach((r) => {
            r.items.forEach((item) => {
                if (item.brotherId) {
                    brotherIdMap[item.brotherId] = [
                        ...(brotherIdMap[item.brotherId] || []),
                        item,
                    ];
                }
            });
        });
        Object.keys(brotherIdMap).forEach((brotherId) => {
            brotherIdMap[brotherId].forEach((item) => {
                brotherIdMap[brotherId].forEach((itemBrother) => {
                    if (itemBrother != item) {
                        item.addBrother(itemBrother);
                    }
                });
            });
        });
    }
    /**
     * 选中时间线的元素
     * @param item ITimelineItem | ITimelineItem[]
     * @param isEvene 是否触发事件
     */
    selectItem(item, isEvene = true) {
        item = Array.isArray(item) ? item : [item];
        this.rows.forEach((row) => row.items.forEach((item) => (item.isSelected = false)));
        item.forEach((item) => {
            item.isSelected = true;
        });
        this.selectedItem = item;
        isEvene && this.emit(EVENT_MAP.select, item);
        this.emit(EVENT_MAP.update, this.currentTime);
    }
    /**
     *  取消选中元素
     * @param item
     * @param isEvene
     */
    cancalSelectItem() {
        this.rows.forEach((row) => row.items.forEach((item) => (item.isSelected = false)));
        this.selectedItem = [];
    }
    /**
     * 移除时间线的元素
     * @param item ITimelineItem
     * @param isEvene 是否触发事件
     */
    removeItem(item, isDeleteEvent = true, isChangeEvent = true) {
        this.rows.forEach((row, i) => {
            item.forEach((item) => {
                const index = row.items.indexOf(item);
                if (index !== -1) {
                    row.items.splice(index, 1);
                    if (!row.items.length) {
                        row.rowIcons.remove();
                        this.rows.splice(i, 1);
                    }
                    isDeleteEvent && this.emit(EVENT_MAP.delete, item);
                }
            });
        });
        this.selectedItem = this.selectedItem.filter((i) => !item.includes(i));
        this.updateScrollbar();
        this.emit(EVENT_MAP.update, this.currentTime);
        if (isChangeEvent) {
            this.emit(EVENT_MAP.onTimelineItemChange);
        }
    }
    /**
     * 清空时间线
     */
    clear(isEvent = true) {
        this.rows = [];
        this.selectedItem = [];
        this.totalFrame = 0;
        this.currentTime = 0;
        this.setCurrentTime(0, true);
        this.timelineRowIcons.innerHTML = "";
        if (isEvent) {
            this.emit(EVENT_MAP.onTimelineItemChange);
        }
    }
    /**
     * 导入时间线数据
     * @param json 导出的时间线json数据
     */
    import(json, isEvent = true) {
        this.stopAnimation();
        if (!json)
            return;
        this.rows = [];
        this.selectedItem = [];
        const data = JSON.parse(json);
        this.fps = data.fps || this.fps;
        const rowMap = data.data.reduce((rowMap, v) => {
            if (!v.lineItem.isTimeLineItem)
                return rowMap;
            const TTimeLineItem = v.lineItem;
            const timeLineItem = new TimelineItem(TTimeLineItem.startTime * this.fps, TTimeLineItem.time * this.fps, TTimeLineItem);
            timeLineItem.id = TTimeLineItem.id;
            timeLineItem.type = TTimeLineItem.type;
            timeLineItem.muted = TTimeLineItem.muted;
            timeLineItem.showDraw = TTimeLineItem.showDraw;
            timeLineItem.friendIds = TTimeLineItem.friendIds;
            const timeLineRow = rowMap[TTimeLineItem.y] || new TimelineRow();
            const img = new Image();
            img.src = TTimeLineItem.icon;
            timeLineRow.icon = TTimeLineItem.icon;
            timeLineRow.type = TTimeLineItem.type;
            timeLineRow.iconImg = img;
            timeLineRow.txtColor = timeLineItem.txtColor;
            timeLineRow.bcColor = timeLineItem.bcColor;
            timeLineRow.name = timeLineItem.sideText; // 新增 时间轴类型 名称
            timeLineRow.addItem(timeLineItem);
            rowMap[TTimeLineItem.y] = timeLineRow;
            return rowMap;
        }, {});
        const idToRowMap = {};
        Object.keys(rowMap)
            .sort((aY, bY) => Number(aY) - Number(bY))
            .forEach((y) => {
            const row = rowMap[y];
            row.muted = row.items.every((item) => item.muted);
            row.showDraw = row.items.every((item) => item.showDraw);
            this.rows.push(rowMap[y]);
            idToRowMap[row.id] = row;
        });
        this.rows.forEach((row) => {
            if (idToRowMap[row.parentId]) {
                row.setParent(idToRowMap[row.parentId]);
            }
        });
        if (isEvent) {
            this.emit(EVENT_MAP.onTimelineItemChange);
        }
        setTimeout(() => {
            this.setTotalTime(data.totalTime / this.fps);
            this.resize();
            this.startAnimation();
        }, 100);
    }
    /**
     * 设置时间线的历史记录
     */
    setHistory(history) {
        if (this.history) {
            this.history.offEventNameAll("indexStatus");
        }
        this.history = history;
        this.history.on("indexStatus", (status) => {
            switch (status) {
                case "none":
                    this.timeLineDirectionLeft.src = DirectionLeftNone;
                    this.timeLineDirectionLeft.dataset.none = "true";
                    this.timeLineDirectionRight.src = DirectionRightNone;
                    this.timeLineDirectionRight.dataset.none = "true";
                    break;
                case "back":
                    this.timeLineDirectionLeft.src = DirectionLeft;
                    this.timeLineDirectionLeft.dataset.none = "false";
                    this.timeLineDirectionRight.src = DirectionRightNone;
                    this.timeLineDirectionRight.dataset.none = "true";
                    break;
                case "forward":
                    this.timeLineDirectionLeft.src = DirectionLeftNone;
                    this.timeLineDirectionLeft.dataset.none = "true";
                    this.timeLineDirectionRight.src = DirectionRight;
                    this.timeLineDirectionRight.dataset.none = "false";
                    break;
                case "other":
                    this.timeLineDirectionLeft.src = DirectionLeft;
                    this.timeLineDirectionLeft.dataset.none = "false";
                    this.timeLineDirectionRight.src = DirectionRight;
                    this.timeLineDirectionRight.dataset.none = "false";
                    break;
            }
        });
    }
    /**
     * 开始时间线播放
     */
    play() {
        if (this.totalFrame == 0)
            return;
        if (this.playing)
            return;
        const playIcon = this.timeLineSlider.querySelector("#time-line-slider-icon.play");
        const pauseIcon = this.timeLineSlider.querySelector("#time-line-slider-icon.pause");
        playIcon.className = "play hide";
        pauseIcon.className = "pause visible";
        this.playing = true;
        this.playingStartTime = this.currentTime;
        this.playingStartNow = Date.now();
        this.emit(EVENT_MAP.onplay, this.currentTime);
        // 杜 添加 判断。 播放时，不可点击历史按钮，禁用
        this.timeLineDirectionLeft.style.cursor = "not-allowed";
        this.timeLineDirectionRight.style.cursor = "not-allowed";
    }
    /**
     * 暂停时间线播放
     */
    pause() {
        if (!this.playing)
            return;
        this.playing = false;
        const playIcon = this.timeLineSlider.querySelector("#time-line-slider-icon.play");
        const pauseIcon = this.timeLineSlider.querySelector("#time-line-slider-icon.pause");
        pauseIcon.className = "pause hide";
        playIcon.className = "play visible";
        this.emit(EVENT_MAP.onpause, this.currentTime);
        // 杜 添加 判断。 播放时，可点击历史按钮，小手
        this.timeLineDirectionLeft.style.cursor = "pointer";
        this.timeLineDirectionRight.style.cursor = "pointer";
    }
    startAnimation() {
        cancelAnimationFrame(this.animationid);
        const animate = () => {
            // 限制 30 帧
            if (Date.now() - this._previousTime < 1000 / this.fps) {
                this.animationid = requestAnimationFrame(animate.bind(this));
                return;
            }
            this._previousTime = Date.now();
            this.redraw();
            this.animationid = requestAnimationFrame(animate.bind(this));
        };
        this._previousTime = Date.now();
        animate();
    }
    stopAnimation() {
        cancelAnimationFrame(this.animationid);
    }
    /**
     * 创建时间线的html元素  !!!非业务使用
     * @param container
     * @param template
     * @returns
     **/
    createHtmlElement(container, template) {
        container.innerHTML = template;
        return {
            timelineContainer: container.querySelector("#timelineContainer"),
            canvasContainer: container.querySelector("#canvasContainer"),
            canvas: container.querySelector("#timelineCanvas"),
            scrollbar: container.querySelector("#scrollbar"),
            scrollbarContainer: container.querySelector("#scrollbarContainer"),
            zoomSliderContainer: container.querySelector("#scale-slider-container"),
            zoomSliderMinus: container.querySelector("#scale-slider-minus"),
            zoomSliderQuanLan: container.querySelector("#scale-slider-quanlan"),
            zoomSliderPlus: container.querySelector("#scale-slider-plus"),
            captionSwitch: container.querySelector("#customSwitch"),
            timeLineSlider: container.querySelector("#time-line-slider"),
            timeLineSliderContainer: container.querySelector("#time-line-slider-container"),
            timeLineSliderTimes: container.querySelector("#time-line-slider-times"),
            timeLineDirection: container.querySelector("#direction"),
            timeLineDirectionLeft: container.querySelector("#direction-left"),
            timeLineDirectionRight: container.querySelector("#direction-right"),
            timelineRowIcons: container.querySelector("#timelineRowIcons"),
        };
    }
    /**
     * 新建row的icon
     * @returns rowIcon
     */
    createRowIcon(src, activeSrc, row) {
        const rowMusicIcon = row.muted ? MuteIcon : VolumeIcon;
        const rowVisibleIcon = row.showDraw ? BrowseIcon : HideIcon;
        const div = document.createElement("div");
        div.className = "row-icons";
        div.setAttribute("data-row-id", row.id);
        div.innerHTML = ` 
      <div class='row-icon'>
        <img class='icon' src="${src}" />
        <img class='active-icon' src="${activeSrc}" />
        <div class='iconText'>${row.name}</div>
      </div>
      <div class="row-btns" style="${!this.config.rowBtnsEnable && "display: none"}">
        <img class="row-music" src="${rowMusicIcon}" />
        <img class="row-visible" src="${rowVisibleIcon}" />
      </div>
    `;
        // 带ICON 版本
        // div.innerHTML = ` 
        //     <img src="${src}" />
        //     <div class="row-btns" style="${
        //       !this.config.rowBtnsEnable && "display: none"
        //     }">
        //       <img class="row-music" src="${rowMusicIcon}" />
        //       <img class="row-visible" src="${rowVisibleIcon}" />
        //     </div>
        // `;
        row.rowIcons = div;
        row.icon = src;
        row.items.forEach((item) => {
            item.muted = row.muted;
            item.showDraw = row.showDraw;
        });
        return div;
    }
    /**
     * 更新row的icon
     */
    updateRowIcon(row) {
        const rowMusicIcon = row.muted ? MuteIcon : VolumeIcon;
        const rowVisibleIcon = row.showDraw ? BrowseIcon : HideIcon;
        row.rowIcons
            ?.querySelector("img.row-music")
            ?.setAttribute("src", rowMusicIcon);
        row.rowIcons
            ?.querySelector("img.row-visible")
            ?.setAttribute("src", rowVisibleIcon);
        row.items.forEach((item) => {
            item.muted = row.muted;
            item.showDraw = row.showDraw;
        });
    }
    calculateTotalWidth() {
        return (this.totalFrame * this.pixelsPerFrame +
            this.padding.right +
            this.padding.left);
    }
    calculateTotalHeight() {
        const totalHeight = this.rows.reduce((total, row) => {
            return Math.max(total, row.y + row.height);
        }, 0) +
            this.timelineRowMarginBottom +
            this.timelineMarginTop;
        return totalHeight;
    }
    updateDpr() {
        this.dpr = Math.max(window.devicePixelRatio, 2);
    }
    initEvents() {
        this.canvas?.addEventListener("wheel", this.handleWheel.bind(this));
        this.canvas?.addEventListener("mousedown", this.handleMouseDown.bind(this));
        document.addEventListener("mousemove", this.handleMouseMove.bind(this));
        document.addEventListener("mouseup", this.handleMouseUp.bind(this));
        window.addEventListener("resize", () => {
            this.updateDpr();
            this.updateCanvasSize();
        });
        const resizeObserver = new ResizeObserver(() => {
            this.updateCanvasSize();
        });
        resizeObserver?.observe(this.canvasContainer);
        /** 前进、后退按钮点击事件 */
        const directionLeft = this.timeLineDirectionLeft;
        const directionRight = this.timeLineDirectionRight;
        directionLeft?.addEventListener("click", () => {
            // 杜 添加 判断。 播放时，不可点击历史按钮，不然播放语音会混乱
            if (directionLeft.dataset.none == "true" || this.playing)
                return;
            this.emit(EVENT_MAP.onback);
        });
        directionRight?.addEventListener("click", () => {
            // 杜 添加  ( this.playing )判断。 播放时，不可点击历史按钮，不然播放语音会混乱
            if (directionRight.dataset.none == "true" || this.playing)
                return;
            this.emit(EVENT_MAP.onforward);
        });
        /** 时间线播放按钮事件  --  开始 */
        // 点击播放按钮
        const playIcon = this.timeLineSlider.querySelector("#time-line-slider-icon.play");
        const pauseIcon = this.timeLineSlider.querySelector("#time-line-slider-icon.pause");
        playIcon.addEventListener("click", this.play.bind(this));
        // 点击暂停按钮
        pauseIcon.addEventListener("click", this.pause.bind(this));
        /** 时间线播放按钮事件  --  结束 */
        /** row-icns点击事件  --  开始 */
        this.timelineRowIcons?.addEventListener("click", (e) => {
            const target = e.target;
            const rowIcons = target.closest(".row-icons");
            if (!rowIcons)
                return;
            const rowId = rowIcons.getAttribute("data-row-id");
            const row = this.rows.find((r) => r.id == rowId);
            if (!row)
                return;
            if (target.className == "row-music") {
                row.muted = !row.muted;
                this.updateRowIcon(row);
                this.emit(EVENT_MAP.update, this.currentTime);
            }
            else if (target.className == "row-visible") {
                row.showDraw = !row.showDraw;
                this.updateRowIcon(row);
                this.emit(EVENT_MAP.update, this.currentTime);
            }
        });
        /** row-icns点击事件  --  结束 */
        // 键盘事件
        const deleteKeys = ["Delete"];
        // const deleteKeys = ["Delete", "Backspace"];
        const returnTagName = ["INPUT", "TEXTAREA"];
        document.addEventListener("keydown", (e) => {
            // @ts-ignore
            if (returnTagName.includes(e.target.nodeName))
                return;
            if (deleteKeys.includes(e.key) && this.selectedItem.length) {
                this.removeItem(this.selectedItem, true, false);
            }
        });
        // this.canvas?.addEventListener("click", (e) => {
        //   const rect = this.canvas?.getBoundingClientRect();
        //   if (!rect) return;
        //   const mouseX = e.clientX - rect.left + this.offsetX - this.padding.left;
        //   const mouseY = e.clientY - rect.top + this.offsetY - this.padding.top;
        //   for (let i = 0; i < this.rows.length; i++) {
        //     const row = this.rows[i];
        //     for (let j = 0; j < row.items.length; j++) {
        //       const item = row.items[j];
        //       if (item.isCloseButtonClicked(mouseX, mouseY)) {
        //         // 移除点击的 TimelineItem
        //         row.items.splice(j, 1);
        //         if (!row.items.length) {
        //           row.iconImg!.remove();
        //           this.rows.splice(i, 1);
        //         }
        //         this.emit(EVENT_MAP.delete, item);
        //         return; // 停止循环，因为已经处理了点击事件
        //       }
        //     }
        //   }
        // });
    }
    initCaptionSwitch() {
        this.captionSwitch.onclick = (e) => {
            // @ts-ignore
            const value = e.target?.checked;
            this.emit(EVENT_MAP.onCaptionSwitch, value);
        };
    }
    setCaptionSwitchValue = (value) => {
        if (this.captionSwitch && typeof value === 'boolean') {
            this.captionSwitch.checked = value;
        }
    };
    initScrollbar() {
        if (!this.scrollbar || !this.scrollbarContainer)
            return;
        this.scrollbar.style.width = `${this.totalWidth}px`;
        this.scrollbarContainer.onscroll = () => {
            if (!this.scrollbar || !this.scrollbarContainer)
                return;
            if (this.autoScrollInterval && this.selectedItem.length) {
                const timeDiff = (this.scrollbarContainer.scrollLeft - this.offsetX) /
                    this.pixelsPerFrame;
                this.selectedItem.forEach((item) => item.moveTimeLineX(timeDiff));
                // this.dragStartX =
                //   this.dragStartX - this.offsetX + this.scrollbarContainer.scrollLeft;
            }
            this.offsetX = this.scrollbarContainer.scrollLeft;
            this.offsetY = this.scrollbarContainer.scrollTop;
        };
    }
    initZoomSlider() {
        this.zoomSlider.oninput = () => {
            const valueAsNumber = this.zoomSlider.getValue();
            this.setZoom(valueAsNumber);
        };
        this.zoomSliderPlus.onclick = () => {
            const value = this.zoomSlider.getValue() - 1;
            this.zoomSlider.setValue(value);
        };
        this.zoomSliderMinus.onclick = () => {
            const value = this.zoomSlider.getValue() + 1;
            this.zoomSlider.setValue(value);
        };
        this.zoomSliderQuanLan.onclick = () => {
            if (this.rows.length === 0)
                return;
            const nextpixelsPerFrame = (this.canvas.clientWidth - this.canvas.clientWidth * 1 / 20) / this.totalFrame;
            const nextScale = this.basePixelsPerFrame / nextpixelsPerFrame;
            this.setZoom(nextScale);
        };
    }
    // 缩小
    zoomOut() {
        const value = this.zoomSlider.getValue() + 1;
        this.zoomSlider.setValue(value);
    }
    // 放大
    zoomIn() {
        const value = this.zoomSlider.getValue() - 1;
        this.zoomSlider.setValue(value);
    }
    initTimeLineSliderContainer() {
        this.timeLineSliderContainer.oninput = ({ isClick }) => {
            const value = this.timeLineSliderContainer.getValue();
            const time = (this.totalFrame * value) / 100;
            if (isClick) {
                this.playingStartTime = time;
                this.playingStartNow = Date.now();
            }
            this.setCurrentTime(time);
            this.timeLineSliderTimes.innerText = `${framesToTimecode(this.currentTime)}/${framesToTimecode(this.totalFrame)}`;
        };
    }
    updateScrollbar() {
        this.totalWidth = this.calculateTotalWidth();
        this.totalHeight = this.calculateTotalHeight();
        if (!this.scrollbar)
            return;
        this.scrollbar.style.width = `${this.totalWidth + this.pixelsPerFrame + this.padding.right}px`;
        this.scrollbar.style.height = `${this.totalHeight + 10}px`;
    }
    startAutoScroll = debounce((direction) => {
        if (!this.isDown)
            return;
        const update = () => {
            this.scrollbarContainer.scrollLeft += 20 * direction;
        };
        if (this.autoScrollInterval !== null) {
            clearInterval(this.autoScrollInterval);
        }
        this.autoScrollInterval = window.setInterval(update, 100);
    }, 1000);
    handleWheel(event) {
        event.preventDefault();
        const deltaY = event.deltaY;
        if (this.isScrollY) {
            if (deltaY > 0) {
                this.scrollbarContainer.scrollTop += 6;
            }
            else {
                this.scrollbarContainer.scrollTop -= 6;
            }
        }
    }
    setZoom(scale) {
        this.scale = scale;
        this.pixelsPerFrame = this.basePixelsPerFrame / this.scale;
        this.totalWidth = this.calculateTotalWidth();
        this.rows.forEach((row) => row.items.forEach((item) => item.setPixelsPerSecond(this.pixelsPerFrame)));
        this.updateScrollbar();
    }
    handleMouseDown(event) {
        this.isDown = true;
        this.dragStartX = event.pageX;
        this.dragStartY = event.pageY;
        this.mousePositinTypeObj = this.getMousePositionType(event);
        this.changeMouseCursor(this.mousePositinTypeObj.type);
        switch (this.mousePositinTypeObj.type) {
            case "timeLineItem":
                this.selectItem(this.mousePositinTypeObj.data);
                this.selectedItem.forEach((item) => {
                    item.status = "move";
                    item.timeLineRow.children.forEach((row) => {
                        row.items.forEach((i) => {
                            i.status = "move";
                        });
                    });
                });
                break;
            case "timeLineItemLeftHandle":
                this.selectedItem.forEach((item) => {
                    item.status = "timeChange";
                });
                break;
            case "timeLineItemRightHandle":
                this.selectedItem.forEach((item) => {
                    item.status = "timeChange";
                });
                break;
            default:
                this.selectedItem.forEach((item) => {
                    item.status = "none";
                });
                break;
        }
    }
    handleMouseMove(event) {
        if (!this.isDown) {
            const { type, data: item } = this.getMousePositionType(event);
            this.changeMouseCursor(type);
        }
        else {
            this.isMove = true;
            this.selectedItemChange(event);
        }
    }
    handleMouseUp(event) {
        const mouseX = event.offsetX + this.offsetX - this.padding.left;
        const mouseY = event.offsetY + this.offsetY - this.padding.top;
        if (event.target === this.canvas && (!this.isMove || this.mousePositinTypeObj.firstTimeLineItemSelect)) {
            const time = Math.round(mouseX / this.pixelsPerFrame);
            this.playingStartTime = time;
            this.playingStartNow = Date.now();
            this.setCurrentTime(time);
        }
        if (this.mousePositinTypeObj.type == "timeLineItem" &&
            this.selectedItem.length == 1 &&
            this.selectedItem[0].isChangeY) {
            this.selectedItem[0].resetTime();
            const selectedItemRow = this.rows.filter((row) => row.items.includes(this.selectedItem[0]))[0];
            const { index, verticalAlign, isBottom } = this.getRowByMousePosition(this.selectedItem[0].getCenter());
            const currentRow = this.rows[index];
            const isSameType = currentRow.icon == selectedItemRow.icon;
            selectedItemRow.items.splice(selectedItemRow.items.indexOf(this.selectedItem[0]), 1);
            if (isSameType && verticalAlign == "center") {
                currentRow.items.push(this.selectedItem[0]);
                currentRow.rearrangeItems();
            }
            else {
                const row = selectedItemRow.clone();
                row.addItem(this.selectedItem[0]);
                if (isBottom) {
                    this.rows.splice(index + 1, 0, row);
                }
                else {
                    this.rows.splice(index, 0, row);
                }
            }
            this.selectedItem[0].dy_total = 0;
            this.formatRows();
        }
        if ((this.alignmentLineTime || this.alignmentLineTime == 0) && this.selectedItem[0]) {
            if (this.alignmentType === "start") {
                this.selectedItem[0].startTime = this.alignmentLineTime;
            }
            if (this.alignmentType === "end") {
                this.selectedItem[0].startTime = this.alignmentLineTime - this.selectedItem[0].time;
            }
            this.alignmentLineTime = null;
            this.alignmentType = null;
        }
        if (this.mousePositinTypeObj.isTimelineItemChange) {
            this.emit(EVENT_MAP.onTimelineItemChange);
        }
        this.isMove = false;
        this.isDown = false;
        this.mousePositinTypeObj.isTimelineItemChange = false;
        this.mousePositinTypeObj.firstTimeLineItemSelect = false;
        this.mousePositinTypeObj.type = "none";
        // this.selectedItem = null;
        if (this.autoScrollInterval !== null) {
            clearInterval(this.autoScrollInterval);
            this.autoScrollInterval = null;
        }
        this.canvas.style.cursor = "default";
    }
    changeMouseCursor(type) {
        switch (type) {
            case "timeLine":
                this.canvas.style.cursor = "ew-resize";
                break;
            case "timeLineItem":
                this.canvas.style.cursor = "move";
                break;
            case "timeLineItemLeftHandle":
            case "timeLineItemRightHandle":
                this.canvas.style.cursor = "e-resize";
                break;
            default:
                this.canvas.style.cursor = "default";
        }
    }
    selectedItemChange(event) {
        if (!this.canvas || this.mousePositinTypeObj.type == "none")
            return;
        const mouseX = event.pageX;
        const mouseY = event.pageY;
        const dx = mouseX - this.dragStartX;
        const dy = mouseY - this.dragStartY;
        const selectedItem = this.selectedItem[0];
        if (Math.abs(dx) >= this.pixelsPerFrame) {
            const time = dx / this.pixelsPerFrame;
            // 临时解决 减小两个视频拼接间隙 du
            // const timeDiff = time > 0 ? Math.floor(time) : Math.ceil(time);
            const timeDiff = time; //> 0 ? Math.floor(time) : Math.ceil(time);
            if (this.mousePositinTypeObj.type == "timeLine") {
                this.setCurrentTime(this.currentTime + timeDiff);
                this.dragStartX = timeDiff * this.pixelsPerFrame + this.dragStartX;
            }
            else if (selectedItem) {
                switch (this.mousePositinTypeObj.type) {
                    case "timeLineItem":
                        const boundaries = selectedItem.isBeyondTimelineBoundaries(this.offsetX, this.canvas.clientWidth, timeDiff > 0 ? 1 : -1);
                        if (boundaries.isBeyondLeft) {
                            this.startAutoScroll(-1);
                        }
                        else if (boundaries.isBeyondRight) {
                            this.startAutoScroll(1);
                        }
                        else if (this.autoScrollInterval) {
                            clearInterval(this.autoScrollInterval);
                            this.autoScrollInterval = null;
                        }
                        // 正常移动逻辑
                        selectedItem.moveTimeLineX(timeDiff, (timeDiff) => {
                            this.dragStartX = timeDiff * this.pixelsPerFrame + this.dragStartX;
                        });
                        // 检查吸附逻辑，仅调整 startTime，不影响 time
                        if (!this.alignmentLineTime) {
                            const { time, type } = this.checkAlignment(selectedItem);
                            this.alignmentLineTime = time;
                            this.alignmentType = type;
                        }
                        if (this.alignmentLineTime) {
                            const snapTimeDiff = this.alignmentType == 'start' ? this.alignmentLineTime - selectedItem.startTime : this.alignmentLineTime - (selectedItem.startTime + selectedItem.time);
                            // 如果timeLineItem距离小于5px，吸附并显示对齐虚线
                            if (Math.abs(snapTimeDiff * this.pixelsPerFrame) > this.alignmentThreshold) {
                                // 如果滑动超过5px，隐藏对齐虚线并取消吸附
                                this.alignmentLineTime = null;
                            }
                        }
                        this.emit(EVENT_MAP.update, this.currentTime);
                        break;
                    case "timeLineItemLeftHandle":
                        selectedItem.setStartTime(timeDiff, (timeDiff) => {
                            this.dragStartX =
                                timeDiff * this.pixelsPerFrame + this.dragStartX;
                        });
                        this.emit(EVENT_MAP.update, this.currentTime);
                        break;
                    case "timeLineItemRightHandle":
                        selectedItem.setEndTime(timeDiff, (timeDiff) => {
                            this.dragStartX =
                                timeDiff * this.pixelsPerFrame + this.dragStartX;
                        });
                        this.emit(EVENT_MAP.update, this.currentTime);
                        break;
                }
            }
        }
        if (selectedItem && selectedItem.status == "move") {
            selectedItem.moveY(dy);
        }
        this.dragStartY = mouseY;
    }
    // 检查对齐
    checkAlignment(selectedItem) {
        let closestPosition = null;
        let alignmentType = null;
        // 获取 selectedItem 的开始时间和结束时间
        const selectedStartTime = selectedItem.startTime;
        const selectedEndTime = selectedItem.startTime + selectedItem.time;
        // 定义吸附的距离阈值，以5px为单位
        const alignmentThresholdInFrames = 5 / this.pixelsPerFrame;
        // 遍历所有的 rows 和 items
        this.rows.forEach(row => {
            row.items.forEach(item => {
                if (item === selectedItem)
                    return; // 跳过当前的 selectedItem
                const itemStartTime = item.startTime;
                const itemEndTime = item.startTime + item.time;
                // 规则1：selectedItem 的开始时间等于其他 item 的开始时间或结束时间，或者距离小于5px
                if (Math.abs(selectedStartTime - itemStartTime) <= alignmentThresholdInFrames) {
                    closestPosition = itemStartTime;
                    alignmentType = 'start';
                    return;
                }
                if (Math.abs(selectedStartTime - itemEndTime) <= alignmentThresholdInFrames) {
                    closestPosition = itemEndTime;
                    alignmentType = 'start';
                    return;
                }
                // 规则2：selectedItem 的结束时间等于其他 item 的开始时间或结束时间，或者距离小于5px
                if (Math.abs(selectedEndTime - itemStartTime) <= alignmentThresholdInFrames) {
                    closestPosition = itemStartTime;
                    alignmentType = 'end';
                    return;
                }
                if (Math.abs(selectedEndTime - itemEndTime) <= alignmentThresholdInFrames) {
                    closestPosition = itemEndTime;
                    alignmentType = 'end';
                    return;
                }
            });
        });
        return { time: closestPosition, type: alignmentType };
    }
    getRowByMousePosition({ x, y }) {
        const indexFloat = (y - this.timelineMarginTop) /
            (this.timelineRowHeight + this.timelineRowMarginBottom);
        const maxIndex = this.rows.length - 1;
        const minIndex = 0;
        let index = Math.floor(indexFloat);
        if (index < minIndex) {
            index = minIndex;
        }
        if (index > maxIndex) {
            index = maxIndex;
        }
        const diff = indexFloat - index;
        const isBottom = diff > 0.5;
        let verticalAlign;
        if (diff < 0.33) {
            verticalAlign = "top";
        }
        else if (diff >= 0.66) {
            verticalAlign = "bottom";
        }
        else {
            verticalAlign = "center";
        }
        return { index, verticalAlign, isBottom };
    }
    getMousePositionType(event) {
        const mouseX = event.offsetX + this.offsetX - this.padding.left;
        const mouseY = event.offsetY + this.offsetY - this.padding.top;
        // this.selectedItem = null;
        const currentTimeWidth = this.currentTime * this.pixelsPerFrame;
        const typeObj = { type: "none", data: null, isTimelineItemChange: false, firstTimeLineItemSelect: false };
        if (Math.abs(mouseX - currentTimeWidth) <= 10) {
            typeObj.type = "timeLine";
            typeObj.isTimelineItemChange = false;
        }
        else {
            this.rows.forEach((row) => {
                row.items.forEach((item) => {
                    if (mouseY >= item.y &&
                        mouseY <= item.y + item.height &&
                        mouseX >= item.x &&
                        mouseX <= item.x + item.width) {
                        typeObj.type = "timeLineItem";
                        typeObj.data = item;
                        if (!item.isSelected) {
                            typeObj.firstTimeLineItemSelect = true;
                        }
                        typeObj.isTimelineItemChange = true;
                        if (item.isSelected && item.durationModifiable) {
                            if (item.isOnLeftHandle(mouseX)) {
                                typeObj.type = "timeLineItemLeftHandle";
                                typeObj.data = item;
                            }
                            else if (item.isOnRightHandle(mouseX)) {
                                typeObj.type = "timeLineItemRightHandle";
                                typeObj.data = item;
                            }
                        }
                    }
                });
            });
        }
        return typeObj;
    }
    draw() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.save();
        this.ctx.translate(this.padding.left - this.offsetX, this.padding.top - this.offsetY);
        // 计算总时长
        let totalFrame = 0;
        this.rows.forEach((row, i) => {
            totalFrame = row.items.reduce((t, item) => Math.max(item.startTime + item.time, t), totalFrame);
        });
        if (totalFrame !== this.totalFrame) {
            this.setTotalTime(totalFrame / this.fps);
        }
        // 绘制row
        this.rows.forEach((row, i) => {
            row.height = this.timelineRowHeight;
            row.y =
                i * this.timelineRowHeight +
                    i * this.timelineRowMarginBottom +
                    this.timelineMarginTop;
            row.totalFrame = this.totalFrame;
            row.pixelsPerFrame = this.pixelsPerFrame;
            row.rowIcons.style.top = `${row.y - this.offsetY + this.padding.top}px`;
            row.draw(this.ctx);
            if (row.items.find((item) => item.isSelected)) {
                row.rowIcons.classList.add("selected");
            }
            else {
                row.rowIcons.classList.remove("selected");
            }
        });
        // 额外绘制选中的元素，令其置顶
        this.selectedItem.forEach((item) => {
            item.draw(this.ctx);
        });
        this.ctx.restore();
        this.drawTimeScale();
        this.drawCurrentTimeLine();
        this.drawAlignmentLine(); // 绘制对齐虚线
    }
    // 绘制对齐虚线
    drawAlignmentLine() {
        if (this.alignmentLineTime === null)
            return;
        const x = this.alignmentLineTime * this.pixelsPerFrame - this.offsetX + this.padding.left;
        this.ctx.save();
        this.ctx.strokeStyle = this.alignmentLineColor; // 对齐虚线的颜色
        this.ctx.setLineDash(this.alignmentLineDash); // 设置虚线样式
        this.ctx.beginPath();
        this.ctx.moveTo(x, 0);
        this.ctx.lineTo(x, this.canvas.height);
        this.ctx.stroke();
        this.ctx.restore();
    }
    drawTimeScale() {
        if (!this.ctx || !this.canvas)
            return;
        const pixelsPerFrame = this.pixelsPerFrame;
        const scaleHeight = 10; // 时间刻度的高度
        const { interval, positionPoint } = findIntervalAdjusted(Math.round(this.basePixelsPerFrame / pixelsPerFrame), this.fpsDivisors);
        const minFramePerMark = positionPoint;
        const TimeScaleType = getTimeScaleType(minFramePerMark, this.fps);
        const formatFrameF = getFormatFrameF(TimeScaleType);
        let frame = Math.floor((this.offsetX + this.padding.left) / pixelsPerFrame);
        frame = frame - (frame % minFramePerMark);
        // 绘制背景颜色
        this.ctx.fillStyle = "#fff"; // 背景颜色，可以根据需要更改
        // 考虑 padding.top 和 padding.bottom
        this.ctx.fillRect(0, 0, this.canvas.width, scaleHeight + this.padding.top + 8);
        this.ctx.fillStyle = "#AAAAAA"; // 文字颜色
        this.ctx.font = "10px Arial"; // 设置字体大小和样式，根据需要调整
        // 开始绘制时间刻度
        this.ctx.beginPath();
        this.ctx.strokeStyle = "#AAAAAA"; // 设置时间刻度的颜色
        while (frame * pixelsPerFrame <=
            this.canvas.width + this.offsetX - this.padding.right) {
            const x = frame * pixelsPerFrame - this.offsetX + this.padding.left; // 减去offsetX以确保时间刻度与视口同步，并加上左边距
            // 绘制刻度线
            this.ctx.moveTo(x, this.padding.top);
            this.ctx.lineTo(x, this.padding.top + scaleHeight);
            this.ctx.fillText(`${formatFrameF(frame, this.fps)}`, x + 2, scaleHeight + this.padding.top);
            frame += minFramePerMark;
        }
        this.ctx.stroke();
        this.ctx.closePath();
    }
    drawCurrentTimeLine() {
        const linePosition = this.currentTime * this.pixelsPerFrame - this.offsetX + this.padding.left;
        this.ctx.beginPath();
        this.ctx.moveTo(linePosition, 0);
        this.ctx.lineTo(linePosition, this.canvas.height);
        this.ctx.strokeStyle = "#3474D7";
        this.ctx.stroke();
        // 在竖线顶部绘制一个圆形作为指示图案
        this.ctx.beginPath();
        this.ctx.moveTo(linePosition - 4, 0);
        this.ctx.moveTo(linePosition + 4, 0);
        this.ctx.lineTo(linePosition + 4, 10);
        this.ctx.lineTo(linePosition, 14);
        this.ctx.lineTo(linePosition - 4, 10);
        this.ctx.lineTo(linePosition - 4, 0);
        this.ctx.fillStyle = "#3474D7";
        this.ctx.stroke();
        this.ctx.fill();
    }
    redraw() {
        if (this.playing) {
            // 检测是否播放到最后一帧
            if (this.currentTime >= this.totalFrame) {
                this.playingStartNow = Date.now();
                this.playingStartTime = 0;
                // 如果设置了循环播放，则不暂停播放
                if (!this.config.playLoop) {
                    // 暂停播放
                    const pauseIcon = this.timeLineSlider.querySelector("#time-line-slider-icon.pause");
                    pauseIcon.click();
                }
            }
            const now = Date.now();
            const diff = Math.abs(now - this.playingStartNow);
            const ft = 1000 / this.fps;
            this.setCurrentTime(Math.floor(diff / ft) + this.playingStartTime);
        }
        this.draw();
    }
}
