export class CustomSlider {
    container;
    thumb;
    track;
    slider;
    startValue = 0;
    endValue = 100;
    currentValue = 0;
    defaultConfig = {
        interval: [0, 100],
    };
    constructor(container, config) {
        config = Object.assign({}, this.defaultConfig, config);
        this.container = container;
        this.startValue = config.interval[0];
        this.endValue = config.interval[1];
        const template = `
      <div class="video-idea_slider">
        <div class="video-idea_slider-track"></div>
        <div class="video-idea_slider-thumb"></div>
      </div>
    `;
        this.container.innerHTML = template;
        this.thumb = this.container.querySelector(".video-idea_slider-thumb");
        this.track = this.container.querySelector(".video-idea_slider-track");
        this.slider = this.container.querySelector(".video-idea_slider");
        this.initEvents();
        this.setValue(this.currentValue, true);
    }
    initEvents() {
        let isDragging = false;
        let lastClientX = 0;
        const startDrag = (e) => {
            e.preventDefault();
            isDragging = true;
            lastClientX = e.clientX;
            document.addEventListener("mousemove", dragging);
            document.addEventListener("mouseup", stopDrag);
        };
        const dragging = (e) => {
            if (!isDragging)
                return;
            const moveDirection = e.clientX - lastClientX;
            lastClientX = e.clientX;
            let delta = (moveDirection / this.container.getBoundingClientRect().width) *
                Math.abs(this.endValue - this.startValue);
            if (this.startValue > this.endValue) {
                delta = -delta; // Invert the direction of change if startValue is greater than endValue
            }
            this.setValue(this.currentValue + delta);
        };
        const stopDrag = () => {
            isDragging = false;
            document.removeEventListener("mousemove", dragging);
            document.removeEventListener("mouseup", stopDrag);
        };
        this.thumb.addEventListener("mousedown", startDrag);
        // 添加点击事件到滑道上
        this.slider.addEventListener("mousedown", (e) => {
            if (e.target !== this.thumb) {
                // 避免与thumb的mousedown事件冲突
                const trackRect = this.slider.getBoundingClientRect();
                const clickPosition = (e.clientX - trackRect.left) / trackRect.width; // 计算点击位置的百分比
                const valueRange = Math.abs(this.endValue - this.startValue);
                let newValue = this.startValue + clickPosition * valueRange;
                if (this.startValue > this.endValue) {
                    newValue = this.startValue - clickPosition * valueRange; // 处理倒序滑块的情况
                }
                this.setValue(newValue, false, true);
            }
        });
    }
    setValue(newValue, reload = false, isClick = false) {
        newValue = Math.min(Math.max(newValue, Math.min(this.startValue, this.endValue)), Math.max(this.startValue, this.endValue));
        if (this.currentValue !== newValue || reload) {
            this.currentValue = newValue;
            this.oninput({ isClick });
            const totalRange = Math.abs(this.endValue - this.startValue);
            const adjustedValue = Math.abs(this.currentValue - this.startValue);
            const percentage = (adjustedValue / totalRange) * 100;
            this.thumb.style.left = `calc(${percentage}% - ${this.thumb.offsetWidth / 2}px)`;
            this.track.style.width = `${percentage}%`;
        }
    }
    getValue() {
        return this.currentValue;
    }
    oninput = ({ isClick: boolean }) => { }; // Callback to handle input event
}
