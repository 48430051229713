export class RightClickActionBox {
    parent;
    rightActionBoxWH;
    parentId;
    target;
    rightActionBox;
    drawList;
    status = "normal";
    constructor(params) {
        (this.parentId = params?.parentId), (this.parent = params?.parent);
        this.rightActionBoxWH = params?.rightActionBoxWH || this.rightActionBoxWH;
        this.target = params?.target;
        this.drawList = params?.drawList;
        this.rightActionBox = this.renderRightActionBox();
    }
    updateListAndTarget(list, target) {
        this.drawList = list;
        this.target = target;
    }
    // 右键 工具box
    renderRightActionBox() {
        const ul = document.createElement("ul");
        const li = document.createElement("li");
        const li2 = document.createElement("li");
        const li3 = document.createElement("li");
        const li4 = document.createElement("li");
        const li5 = document.createElement("li");
        const wrapper = document.getElementById(this.parentId);
        const urlStyle = `position: absolute; top: 10px; left: 100px; width:145px; display: none;background-color: #fff;box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, .06), 0px 2px 12px 0px rgba(0, 0, 0, .1);border-radius: 8px;padding: 8px; list-style: none; color: rgba(0, 0, 0, .6);font-size:14px`;
        const liStyle = "line-height:30px;margin-bottom: 4px; padding:4px 8px;border-radius: 4px;cursor: pointer;text-align: start;color: #AAAAAA";
        const liStyle2 = "line-height:30px;margin-bottom: 4px; padding:4px 8px;border-radius: 4px;cursor: pointer;text-align: start;color: #43465E";
        wrapper?.setAttribute("style", "position: relative");
        ul.setAttribute("style", urlStyle);
        ul.setAttribute("id", "rightActionBox");
        li.setAttribute("style", liStyle);
        li2.setAttribute("style", liStyle2);
        li3.setAttribute("style", liStyle + ";color:#EE493D;border-top: 2px solid #E7E7E7");
        li4.setAttribute("style", liStyle);
        li5.setAttribute("style", liStyle2);
        li.textContent = "上移一层";
        li2.textContent = "下移一层";
        li3.textContent = "删除";
        li4.textContent = "置于顶层";
        li5.textContent = "置于底层";
        li.onmousemove = () => {
            li.style.background = "#EBEBEB";
        };
        li2.onmousemove = () => {
            li2.style.background = "#EBEBEB";
        };
        li3.onmousemove = () => {
            li3.style.background = "#EBEBEB";
        };
        li4.onmousemove = () => {
            li4.style.background = "#EBEBEB";
        };
        li5.onmousemove = () => {
            li5.style.background = "#EBEBEB";
        };
        li.onmouseout = () => {
            li.style.background = "none";
        };
        li2.onmouseout = () => {
            li2.style.background = "none";
        };
        li3.onmouseout = () => {
            li3.style.background = "none";
        };
        li4.onmouseout = () => {
            li4.style.background = "none";
        };
        li5.onmouseout = () => {
            li5.style.background = "none";
        };
        li.onclick = () => {
            if (this.status !== "disabled" && this.status !== "top") {
                this.upItemLevel(this.target);
            }
            this.hideRightActionBox();
        };
        li2.onclick = () => {
            if (this.status !== "disabled" && this.status !== "bottom") {
                this.downItemLevel(this.target);
            }
            this.hideRightActionBox();
        };
        // 删除
        li3.onclick = () => {
            this.parent?.remove(this.target);
            this.hideRightActionBox();
        };
        // 置顶
        li4.onclick = () => {
            if (this.status !== "disabled" && this.status !== "top") {
                this.toTopLayer(this.target.id);
            }
            this.hideRightActionBox();
        };
        //置底
        li5.onclick = () => {
            if (this.status !== "disabled" && this.status !== "bottom") {
                this.toBtmLayer(this.target.id);
            }
            this.hideRightActionBox();
        };
        ul.appendChild(li);
        ul.appendChild(li2);
        ul.appendChild(li4);
        ul.appendChild(li5);
        ul.appendChild(li3);
        wrapper && wrapper.appendChild(ul);
        return ul;
    }
    afterChangeLayerLevel() {
        this.drawList.forEach((x, i) => {
            x.index = i;
            x.self.index = i;
        });
    }
    checkLayerLevel(frame) {
        const index = frame?.index;
        const length = this.drawList.length;
        switch (true) {
            case length === 1:
                this.status = "disabled";
                break;
            case index === 0:
                this.status = "bottom";
                break;
            case index === length - 1:
                this.status = "top";
                break;
            default:
                this.status = "normal";
                break;
        }
        this.updateLiStyleByStatus(this.status);
    }
    updateLiStyleByStatus(status) {
        const eles = this.rightActionBox?.children || [];
        const normalColor = "#43465E";
        const disabledColor = "#aaaaaa";
        if (status === "disabled") {
            for (let index = 0; index < eles.length; index++) {
                const el = eles[index];
                if (index !== 4) {
                    el.style.color = disabledColor;
                }
                el.style.cursor = "not-allowed";
            }
            return;
        }
        if (status === "normal") {
            for (let index = 0; index < eles.length; index++) {
                const el = eles[index];
                if (index !== 4) {
                    el.style.color = normalColor;
                }
                el.style.cursor = "default";
            }
            return;
        }
        if (status === "top") {
            for (let index = 0; index < eles.length; index++) {
                const el = eles[index];
                if (index === 0 || index === 2) {
                    el.style.color = disabledColor;
                    el.style.cursor = "not-allowed";
                }
                if (index === 1 || index === 3) {
                    el.style.color = normalColor;
                    el.style.cursor = "default";
                }
            }
            return;
        }
        if (status === "bottom") {
            for (let index = 0; index < eles.length; index++) {
                const el = eles[index];
                if (index === 1 || index === 3) {
                    el.style.color = disabledColor;
                    el.style.cursor = "not-allowed";
                }
                if (index === 0 || index === 2) {
                    el.style.color = normalColor;
                    el.style.cursor = "default";
                }
            }
        }
    }
    showRightActionBox(x, y) {
        this.rightActionBox.style.display = "block";
        this.rightActionBox.style.top = `${x}px`;
        this.rightActionBox.style.left = `${y}px`;
    }
    hideRightActionBox() {
        if (this.rightActionBox.style.display !== "none") {
            this.rightActionBox.style.display = "none";
        }
    }
    //置顶图层
    toTopLayer(id) {
        const list = [...this.drawList];
        // 置顶则需要排在最后面
        // list.sort((m, n) => (m.id === id ? -1 : 0)).reverse();
        let d = undefined;
        list.forEach((x, i) => {
            if (x.id === id) {
                d = list.splice(i, 1);
                list.push(d[0]);
            }
        });
        list.forEach((x, i) => {
            x.index = i;
            x.self.index = i;
        });
        this.drawList = list;
    }
    //置底图层
    toBtmLayer(id) {
        const list = [...this.drawList];
        // 置顶则需要排在最后面
        list.sort((m, n) => (m.id === id ? -1 : 0));
        list.forEach((x, i) => {
            x.index = i;
        });
        this.drawList = list;
    }
    // 层级提高一层
    upItemLevel = (frame) => {
        if (this.drawList.length > 1) {
            const index = this.drawList.findIndex((x) => x.id === frame.id);
            if (index !== -1) {
                if (index === this.drawList.length - 1) {
                    // alert("当前元素已在最顶层");
                    return;
                }
                this.drawList[index].index = index + 1;
                this.drawList[index + 1].index = index - 1;
                this.drawList[index] = this.drawList.splice(index + 1, 1, this.drawList[index])[0];
            }
            this.afterChangeLayerLevel();
        }
        else {
            // alert("当前元素已在最顶层");
        }
    };
    // 层级降低一层
    downItemLevel = (frame) => {
        if (this.drawList.length > 1) {
            const index = this.drawList.findIndex((x) => x.id === frame.id);
            if (index !== -1) {
                if (index === 0) {
                    // alert("当前元素已在最底层");
                    return;
                }
                this.drawList[index].index = index - 1;
                this.drawList[index - 1].index = index + 1;
                this.drawList[index] = this.drawList.splice(index - 1, 1, this.drawList[index])[0];
            }
            this.afterChangeLayerLevel();
        }
        else {
            // alert("当前元素已在最底层");
        }
    };
}
