export default class EventEmitter {
    events = {};
    // 添加事件监听器
    on(eventName, listener) {
        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }
        this.events[eventName].push(listener);
    }
    // 触发事件
    emit(eventName, ...args) {
        setTimeout(() => {
            requestAnimationFrame(() => {
                const listeners = this.events[eventName];
                if (listeners) {
                    listeners.forEach((listener) => {
                        listener(...args);
                    });
                }
            });
        });
    }
    // 移除事件监听器
    off(eventName, listener) {
        const listeners = this.events[eventName];
        if (listeners) {
            const index = listeners.indexOf(listener);
            if (index !== -1) {
                listeners.splice(index, 1);
            }
        }
    }
    // 添加只执行一次的事件监听器
    once(eventName, listener) {
        const onceListener = (...args) => {
            listener(...args);
            this.off(eventName, onceListener);
        };
        this.on(eventName, onceListener);
    }
    // 移除所有指定事件监听器
    offEventNameAll(eventName) {
        this.events[eventName] = [];
    }
    // 移除所有事件监听器
    offAll() {
        this.events = {};
    }
}
