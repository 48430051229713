// 生成 16 进制指定长度的字符串
function getRandom(len) {
    return Math.floor((1 + Math.random()) * 16 ** len)
        .toString(16)
        .substring(1);
}
export function uuid() {
    const tempUrl = URL.createObjectURL(new Blob());
    const uuid = tempUrl.toString();
    URL.revokeObjectURL(tempUrl); // 释放这个url
    return uuid.substring(uuid.lastIndexOf("/") + 1);
}
/**
 *  时间格式化
 * */
export function formatTime(time) {
    let second = Math.ceil(time / 1000);
    const s = second % 60;
    second = Math.floor(second / 60);
    const m = second % 60;
    second = Math.floor(second / 60);
    const h = second % 60;
    return {
        s,
        m,
        h,
        str: `${h === 0 ? "" : `${h < 10 ? "0" : ""}${h}:`}${m < 10 ? "0" : ""}${m}:${s < 10 ? "0" : ""}${s}`,
    };
}
export function formatPlayerTime(frameCount) {
    let f = frameCount % 30;
    frameCount = Math.floor(frameCount / 30);
    let s = frameCount % 60;
    frameCount = Math.floor(frameCount / 60);
    let m = frameCount % 60;
    frameCount = Math.floor(frameCount / 60);
    let h = frameCount;
    return `${h < 10 ? "0" : ""}${h}:${m < 10 ? "0" : ""}${m}:${s < 10 ? "0" : ""}${s}:${f < 10 ? "0" : ""}${f}`;
}
/**
 *  获取随机ID，组件拖到预览视图后就会被设置个ID
 * */
export function getId(prefix = "t") {
    return `${prefix ? `${prefix}-` : ""}${getRandom(5)}${getRandom(3)}-${getRandom(4)}`;
}
/**
 * 下载文件
 * */
export function downloadFileUrl(href, fileName) {
    const downloadElement = document.createElement("a");
    downloadElement.href = href;
    // 下载后文件名
    downloadElement.download = fileName;
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
    // 释放掉blob对象
    window.URL.revokeObjectURL(href);
    downloadElement.href = "";
}
export function computedItemShowArea(trackItem, canvasSize, trackAttr) {
    let { left = 0, top = 0, scale = 100, text, fontSize } = trackAttr;
    const { width, height, type } = trackItem;
    const { width: playerW, height: playerH } = canvasSize;
    let defaultW = playerW;
    let defaultH = playerH;
    if (type === "video") {
        const proportionalW = Math.floor((playerH / height) * width); // 等高宽度
        const proportionalH = Math.floor((playerW / width) * height); // 等宽高度
        // 默认渲染位置
        if (proportionalW > playerW) {
            // 等高场景下宽度溢出，则采用等宽， 高度上下留白
            defaultH = proportionalH;
        }
        else if (proportionalH > playerH) {
            // 等宽场景下高度溢出，则采用等高， 宽度左右留白
            defaultW = proportionalW;
        }
    }
    if (type === "image") {
        defaultW = width;
        defaultH = width;
    }
    if (type === "text") {
        defaultW = text.length * fontSize;
        defaultH = fontSize * 1.2;
    }
    // 由默认位置计算偏移缩放位置
    const scaleW = Math.floor((defaultW * scale) / 100);
    const scaleH = Math.floor((defaultH * scale) / 100);
    const scaleL = Math.floor(left + (defaultW - scaleW) / 2);
    const scaleT = Math.floor(top + (defaultH - scaleH) / 2);
    const diffW = Math.floor(playerW - scaleW);
    const diffH = Math.floor(playerH - scaleH);
    return {
        drawL: scaleL,
        drawT: scaleT,
        drawW: scaleW,
        drawH: scaleH,
        sourceWidth: width,
        sourceHeight: height,
        defaultW,
        defaultH,
        diffW,
        diffH,
    };
}
export function isVideo(type) {
    return type === "video";
}
// 封装json格式化, 避免error
export function getJsonParse(jsonStr) {
    let res = "";
    try {
        res = JSON.parse(jsonStr);
    }
    catch (e) {
        res = "";
    }
    return res;
}
/**
 * 计算旋转角度
 *
 * @param {Array} centerPoint 旋转中心坐标
 * @param {Array} startPoint 旋转起点
 * @param {Array} endPoint 旋转终点
 *
 * @return {number} 旋转角度
 */
export function getRotateAngle(centerPoint, startPoint, endPoint) {
    const [centerX, centerY] = centerPoint;
    const [rotateStartX, rotateStartY] = startPoint;
    const [touchX, touchY] = endPoint;
    // 两个向量
    const v1 = [rotateStartX - centerX, rotateStartY - centerY];
    const v2 = [touchX - centerX, touchY - centerY];
    // 公式的分子
    const numerator = v1[0] * v2[1] - v1[1] * v2[0];
    // 公式的分母
    const denominator = Math.sqrt(Math.pow(v1[0], 2) + Math.pow(v1[1], 2)) *
        Math.sqrt(Math.pow(v2[0], 2) + Math.pow(v2[1], 2));
    const sin = numerator / denominator;
    return Math.asin(sin);
}
/**
 *
 * 根据旋转起点、旋转中心和旋转角度计算旋转终点的坐标
 *
 * @param {Array} startPoint  起点坐标
 * @param {Array} centerPoint  旋转点坐标
 * @param {number} angle 旋转角度
 *
 * @return {Array} 旋转终点的坐标
 */
export function getEndPointByRotate(startPoint, centerPoint, angle) {
    const [centerX, centerY] = centerPoint;
    const [x1, y1] = [startPoint[0] - centerX, startPoint[1] - centerY];
    const x2 = x1 * Math.cos(angle) - y1 * Math.sin(angle);
    const y2 = x1 * Math.sin(angle) + y1 * Math.cos(angle);
    return [x2 + centerX, y2 + centerY];
}
/**
 * 判断落点是否在长方形内
 *
 * @param {Array} point 落点坐标。 数组：[x, y]
 * @param {Array} rect 长方形坐标, 按顺序分别是：左上、右上、左下、右下。
 *                     数组：[[x1, y1], [x2, y2], [x3, y3], [x4, y4]]
 *
 * @return {boolean}
 */
export function isPointInRect(point, rect) {
    if (rect.length < 4)
        return false;
    const [touchX, touchY] = point;
    // 长方形四个点的坐标
    const [[x1, y1], [x2, y2], [x3, y3], [x4, y4]] = rect;
    // 四个向量
    const v1 = [x1 - touchX, y1 - touchY];
    const v2 = [x2 - touchX, y2 - touchY];
    const v3 = [x3 - touchX, y3 - touchY];
    const v4 = [x4 - touchX, y4 - touchY];
    if (v1[0] * v2[1] - v2[0] * v1[1] > 0 &&
        v2[0] * v4[1] - v4[0] * v2[1] > 0 &&
        v4[0] * v3[1] - v3[0] * v4[1] > 0 &&
        v3[0] * v1[1] - v1[0] * v3[1] > 0) {
        return true;
    }
    return false;
}
export const setCoordinate = (pos, size) => {
    return [
        [pos[0], pos[1]],
        [pos[0] + size[0], pos[1]],
        [pos[0], pos[1] + size[1]],
        [pos[0] + size[0], pos[1] + size[1]],
    ];
};
export const getVectorLenth = (v1, v2) => {
    const [x1, y1] = v1;
    const [x2, y2] = v2;
    return (x1 * x2 + y1 * y2) / Math.sqrt(x1 * x1 + y1 * y1);
};
export const getDocPosition = (element) => {
    let eleCom = element;
    if (typeof element === "string")
        eleCom = document.querySelector(eleCom);
    let x = eleCom.offsetLeft;
    let y = eleCom.offsetTop;
    let parent = eleCom.offsetParent;
    while (parent) {
        x += parent.offsetLeft;
        y += parent.offsetTop;
        parent = parent.offsetParent;
    }
    return {
        x,
        y,
    };
};
// 设置最大 图片宽 / 高
export const maxImgWidth = 500;
/**
 * 按最大比例限制图片宽高
 * @param params
 *  type: '16:9'|'9:16',
 * cw: number           // canva.clientWidth
 * ch:number            //  canva.clientHeight
 * imagew: number       // image 默认宽
 * imageh: number       // imgae 默认高
 */
export const limitWAHForImage = (params) => {
    const { type, ch, imagew, imageh, cw } = params;
    let iw = imagew;
    let ih = imageh;
    // const basseScale = 9 / 5;
    if (iw === ih) {
        // if (iw > cw) {
        iw = cw * 0.5;
        ih = cw * 0.5;
        // }
    }
    else {
        const whScale = iw / ih;
        const hwScale = ih / iw;
        // if (type === '16:9') {
        // if (iw > cw) {
        iw = cw * 0.5;
        ih = iw / whScale;
        // } else{
        if (ih > ch) {
            ih = ch * 0.5;
            iw = ih / hwScale;
        }
        // }
        // } else {
        // }
    }
    // if (type === "16:9") {
    //   const l = ch / basseScale;
    //   // 图片是正方形F
    //   if (iw === ih) {
    //     iw = l > maxImgWidth ? maxImgWidth : l;
    //     ih = l > maxImgWidth ? maxImgWidth : l;
    //   } else {
    //     iw = (l * iw) / ih;
    //     ih = l;
    //   }
    // } else {
    //   const l = cw / basseScale;
    //   // 图片是正方形F
    //   if (iw === ih) {
    //     iw = l > maxImgWidth ? maxImgWidth : l;
    //     ih = l > maxImgWidth ? maxImgWidth : l;
    //   } else {
    //     ih = (l * ih) / iw;
    //     iw = l;
    //   }
    // }
    return [iw, ih];
};
/**
 * 255颜色值转16进制颜色值
 * @param n 255颜色值
 * @returns hex 16进制颜色值
 */
export const toHex = (n) => `${n > 15 ? "" : 0}${n.toString(16)}`;
/**
 * 颜色对象转化为16进制颜色字符串
 * @param colorObj 颜色对象
 */
export const toHexString = (colorObj) => {
    const { r, g, b, a = 1 } = colorObj;
    return `#${toHex(r)}${toHex(g)}${toHex(b)}${a === 1 ? "" : toHex(Math.floor(a * 255))}`;
};
/**
 * 颜色对象转化为rgb颜色字符串
 * @param colorObj 颜色对象
 */
export const toRgbString = (colorObj) => {
    const { r, g, b } = colorObj;
    return `rgb(${r},${g},${b})`;
};
/**
 * 颜色对象转化为rgba颜色字符串
 * @param colorObj 颜色对象
 */
export const toRgbaString = (colorObj, n = 10000, defa = 1) => {
    const { r, g, b, a = defa } = colorObj;
    return `rgba(${r},${g},${b},${Math.floor(a * n) / n})`;
};
/**
 * 16进制颜色字符串解析为颜色对象
 * @param color 颜色字符串
 * @returns IColorObj
 */
export const parseHexColor = (color) => {
    let hex = color.slice(1);
    let a = 1;
    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }
    if (hex.length === 8) {
        a = parseInt(hex.slice(6), 16) / 255;
        hex = hex.slice(0, 6);
    }
    const bigint = parseInt(hex, 16);
    return {
        r: (bigint >> 16) & 255,
        g: (bigint >> 8) & 255,
        b: bigint & 255,
        a,
    };
};
/**
 * rgba颜色字符串解析为颜色对象
 * @param color 颜色字符串
 * @returns IColorObj
 */
export const parseRgbaColor = (color) => {
    const arr = color.match(/(\d(\.\d+)?)+/g) || [];
    const res = arr.map((s) => parseInt(s, 10));
    return {
        r: res[0],
        g: res[1],
        b: res[2],
        a: parseFloat(arr[3]),
    };
};
/**
 * 颜色字符串解析为颜色对象
 * @param color 颜色字符串
 * @returns IColorObj
 */
export const parseColorString = (color) => {
    if (color.startsWith("#")) {
        return parseHexColor(color);
    }
    else if (color.startsWith("rgb")) {
        return parseRgbaColor(color);
    }
    else if (color === "transparent") {
        return parseHexColor("#00000000");
    }
    throw new Error(`color string error: ${color}`);
};
/**
 * 颜色字符串解析为各种颜色表达方式
 * @param color 颜色字符串
 * @returns IColorObj
 */
export const getColorInfo = (color) => {
    const colorObj = parseColorString(color);
    const hex = toHexString(colorObj);
    const rgba = toRgbaString(colorObj);
    const rgb = toRgbString(colorObj);
    return {
        hex,
        rgba,
        rgb,
        rgbaObj: colorObj,
    };
};
/**
 * 16进制颜色字符串转化为rgba颜色字符串
 * @param hex 16进制颜色字符串
 * @returns rgba颜色字符串
 */
export const hexToRgba = (hex, opacity) => {
    const colorObj = parseColorString(hex);
    return toRgbaString(colorObj, 10000, opacity);
};
/**
 * rgba颜色字符串转化为16进制颜色字符串
 * @param rgba rgba颜色字符串
 * @returns 16进制颜色字符串
 */
export const rgbaToHex = (rgba) => {
    const colorObj = parseColorString(rgba);
    return toHexString(colorObj);
};
/**
 * 判断当前时移入还是移出动画
 * inShow: 传入的 显示状态
 * currentShow：当前图层的状态
 */
export function checkAnimationStatus(inShow, currentShow) {
    if (inShow !== currentShow) {
        //当前状态不显示， 传入的是是显示，说明是 in
        if (!currentShow && inShow) {
            return "in";
        }
        if (!inShow && currentShow) {
            return "out";
        }
    }
    return "noChange";
}
/**，
 * rote角度 (0 - 6)
 * 旋转角度 （0 90 180 360）
 * rote角度转换旋转读书
 */
export function rotateToDegree(rotate) {
    if (typeof rotate !== "number") {
        return rotate;
    }
    return rotate * (180 / Math.PI);
}
/**
 * degreeToRotate
 * 旋转度数 转 rote 角度
 */
export function degreeToRotate(degree) {
    if (typeof degree !== "number") {
        return degree;
    }
    return degree * (Math.PI / 180);
}
/**
 * 初始化 ppt 模板时，模板已设置 x, y, 则根据已设置的 x, y设置ppt.
 * （暂不考虑） 问题：模板页数，少于上传的 ppt 页数时，多出的 ppt 页数按照最后一页赋值 x, y。
 * params:{
 *    x: number, 模板中的 ppt x
 *    y: number, 模板中的 ppt y
 * }
 *
 * （暂时不需要，因为，x,y,只要在渲染时候传过去就好了。没必要在处理一次）
 */
// export function initPPTPositionByTemplate (x, y) {
// }
/**
 * 如果选择了 设置位置，则根据当前 PPT（宽和高） 图层 ，位置信息 x, y.
 * 横屏九个方位   'topLeft' | 'topCenter'| 'topRight' | 'left' | 'center'| 'right' | 'btmLeft' | 'btmCenter' | 'btmRight'
 * 竖屏需要三个方位（上中下） 'topCenter' | 'center' | 'btmCenter'
 * params
 *  screenType?:  '16:9' | '9:16',  // 屏幕类型
 *  canvasDraw: any,  // canvasDraw 类型
 *  width: number,  // 图层的宽度
 *  height: number, // 图层的高度
 *  defaultPadding?: number;  // 距离边框的距离。 默认时 0
 *  positionType?: 'topLeft' | 'topCenter'| 'topRight' | 'left' | 'center'| 'right' | 'btmLeft' | 'btmCenter' | 'btmRight'  //(9：16 'topCenter' | 'center' | 'btmCenter' )
 */
export function initlayerPositionByCanvasScreenType(params) {
    const { screenType = "16:9", positionType = "center", width, height, canvasDraw, defaultPadding = 0, newFramework, } = params;
    if (typeof width !== "number" || typeof height !== "number") {
        console.error("width | height 可能未传入", "width:", width, "height:", height);
        return;
    }
    const canvasW = canvasDraw?.canvas.width;
    const canvasH = canvasDraw?.canvas.height;
    // 默认 中间中心
    let posXY = canvasDraw?.setLayerToCanvasCenter(width, height);
    // if (screenType === "16:9") {
    switch (positionType) {
        case "topLeft":
            posXY = [0 + defaultPadding, 0 + defaultPadding];
            break;
        case "topCenter":
            posXY[1] = 0 + defaultPadding;
            break;
        case "topRight":
            posXY[0] = canvasW - width - defaultPadding;
            posXY[1] = 0 + defaultPadding;
            break;
        case "left":
            posXY[0] = 0 + defaultPadding;
            break;
        case "right":
            posXY[0] = canvasW - width - defaultPadding;
            break;
        case "btmLeft":
            posXY[0] = 0 + defaultPadding;
            posXY[1] = canvasH - height - defaultPadding;
            break;
        case "btmCenter":
            posXY[1] = canvasH - height - defaultPadding;
            break;
        case "btmRight":
            posXY[0] = canvasW - width - defaultPadding;
            posXY[1] = canvasH - height - defaultPadding;
            break;
        case "vCenter":
            const y2 = (canvasH - height) / 2;
            posXY = [newFramework.point[0][0], y2];
            break;
        case "hCenter":
            const x1 = (canvasW - width) / 2;
            posXY = [x1, newFramework.point[0][1]];
            break;
        default: //center
            posXY = posXY;
            break;
    }
    // }
    // if (screenType === "9:16") {
    //   switch (positionType) {
    //     case "topCenter":
    //       posXY[1] = 0 + defaultPadding;
    //       break;
    //     case "btmCenter":
    //       posXY[1] = canvasH - height - defaultPadding;
    //       break;
    //     default: //center
    //       posXY = posXY;
    //       break;
    //   }
    // }
    return posXY;
}
export function debounce(func, delay = 500, immediate = false) {
    let timer = null;
    return function () {
        if (timer) {
            clearTimeout(timer);
        }
        if (immediate && !timer) {
            func.apply(this, arguments);
        }
        timer = setTimeout(() => {
            func.apply(this, arguments);
        }, delay);
    };
}
export function getPoint(width, height, center) {
    const h = height;
    const w = width;
    const [c_x, c_y] = center;
    const points = [];
    points[0] = [c_x - w / 2, c_y - h / 2];
    points[1] = [c_x + w / 2, c_y - h / 2];
    points[2] = [c_x + w / 2, c_y + h / 2];
    points[3] = [c_x - w / 2, c_y + h / 2];
    return points;
}
