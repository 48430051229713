import { degreeToRotate, getPoint, initlayerPositionByCanvasScreenType, limitWAHForImage, uuid, } from "../utils/common";
import { FrameWork } from "./framework";
export class VideoDraw {
    parentId;
    url;
    x;
    y;
    width;
    height;
    baseW;
    baseH;
    video;
    interval;
    ctx;
    id;
    newFramework;
    parent;
    opacity = 1;
    // 根据位置信息，设置图片 x，y 默认位置
    positionType = "center";
    layerPositionType = 'center';
    defaultPadding = 0; // 距离边框的padding
    // 编辑模式
    center = undefined; //center 编辑模式。可传中心
    angle = 0; // 角度
    rotate = 0; // 接口角度使用
    point;
    baseWH;
    ex; // 编辑状态时， x位置
    ey; // 编辑状态时 y 位置
    frameWorkId = undefined;
    index;
    isShow;
    volume = 0.5;
    constructor(params) {
        this.parentId = params.parentId;
        this.url = params.url;
        this.x = params.x;
        this.y = params.y;
        this.baseW = params.width || 300;
        this.baseH = params.height || 168;
        this.ctx = params.ctx;
        // this.id = uuid();
        this.parent = params.parent;
        this.volume = typeof params.volume === 'number' ? params.volume : this.volume;
        this.positionType = params?.positionType || this.positionType;
        this.defaultPadding = params?.defaultPadding || this.defaultPadding;
        // 编辑模式
        this.center = params?.center || this.center;
        this.angle = params?.angle || this.angle;
        this.point = params?.point || this.point;
        this.baseWH = params?.baseWH;
        this.ex = params?.ex;
        this.ey = params?.ey;
        this.id = params?.id || uuid();
        this.frameWorkId = params?.frameWorkId;
        this.index = params?.index;
        this.isShow = typeof params.isShow === "boolean" ? params.isShow : true;
        this.opacity = params?.opacity || this.opacity;
    }
    intVideo(ctx) {
        this.bindFun();
        this.draw(ctx);
    }
    createVideo(callback) {
        const videoEle = document.createElement("VIDEO");
        const canvasWrapper = document.getElementById(this.parentId);
        const id = uuid();
        // canvas 的高宽
        const cw = this.parent.canvas.width;
        const ch = this.parent.canvas.height;
        const type = this.parent.canvasType;
        videoEle.volume = this.volume;
        videoEle.autoplay = false;
        videoEle.controls = true;
        videoEle.setAttribute("crossOrigin", "anonymous");
        videoEle.setAttribute("id", id);
        videoEle.setAttribute("src", this.url);
        videoEle.setAttribute("preload", "auto");
        const [iw, ih] = limitWAHForImage({
            type,
            ch,
            cw,
            imageh: this.baseH,
            imagew: this.baseW,
        });
        this.width = this.baseWH?.length ? this.baseWH[0] : iw;
        this.height = this.baseWH?.length ? this.baseWH[1] : ih;
        this.baseW = this.width;
        this.baseH = this.height;
        videoEle.width = this.width;
        videoEle.height = this.height;
        // videoEle.setAttribute('autoplay', true)
        // videoEle.setAttribute('controls', true)
        // videoEle.onloadeddata=this.onloadeddata;
        this.video = videoEle;
        canvasWrapper?.append(videoEle);
        videoEle.style.display = "none";
        // const vide = document.getElementById(id);
        // vide.setAttribute('style', 'position:absolute; top:0px;left:0px; z-index: -10')
        // const elW = vide.clientWidth
        // const elH = vide.clientHeight
        // this.width = this.baseW || elW;;
        // this.height = this.baseH || elH;
        // videoEle.width = this.width;
        // videoEle.height = this.height;
        const posXY = this.x
            ? [this.x, this.y]
            : this.positionType === "none"
                ? [this.x, this.y]
                : initlayerPositionByCanvasScreenType({
                    width: this.width,
                    height: this.height,
                    canvasDraw: this.parent,
                    positionType: this.positionType,
                    screenType: this.parent?.canvasType,
                    defaultPadding: this.defaultPadding,
                    newFramework: this.newFramework,
                });
        this.x = this.ex !== undefined ? this.ex : posXY[0];
        this.y = this.ey !== undefined ? this.ey : posXY[1];
        // this.newFramework = new FrameWork({
        //   width: this.width,
        //   height: this.height,
        //   center: this.center || this.parent.center,
        //   angle: this.angle || 0,
        //   // 编辑模式
        //   point: this.point,
        //   baseWH: this.baseWH,
        //   id: this.frameWorkId,
        // });
        const center = [this.x + this.width / 2, this.y + this.height / 2];
        this.newFramework = new FrameWork({
            width: this.width,
            height: this.height,
            center,
            angle: this.angle || 0,
            // 编辑模式
            point: getPoint(this.width, this.height, center),
            baseWH: this.baseWH,
            id: this.frameWorkId,
        });
        callback({
            id: this.id,
            url: this.url,
            x: this.x,
            y: this.y,
            w: this.width,
            h: this.height,
            ele: this.video,
            newFramework: this.newFramework,
            type: "video",
            self: this,
            isShow: this.isShow,
        });
    }
    // 更改 视频的 音量 0 - 1; (0.5 = 50%)
    changeVideoEleVolume = (volume) => {
        this.volume = volume;
        this.video.volume = volume;
    };
    // onloadeddata = (obj) =>{
    //   console.log(obj)
    //   // console.log('this',this, ctx)
    //   // this.drawVideo(this.ctx,)
    // }
    /**
     * 属性面板修改属性方法
     */
    updateXYAngle(params) {
        const { x, y, angle, opacity, width, height } = params;
        if (typeof opacity === "number") {
            this.opacity = opacity;
        }
        const w = typeof width !== undefined ? width : this.width;
        const h = typeof height !== undefined ? height : this.height;
        this.angle = angle;
        this.width = width ? width : this.width;
        this.height = width ? width : this.width;
        this.newFramework.updateXYAngle({
            width: w,
            height: h,
            center: [x + w / 2, y + h / 2],
            angle: degreeToRotate(angle),
        });
        this.parent?.getLayerChangeAttributeCallBack();
    }
    /**
     * 更新当前图片的位置信息
     * positionType: 'topLeft' | 'topCenter'| 'topRight' | 'left' | 'center'| 'right' | 'btmLeft' | 'btmCenter' | 'btmRight'；
     */
    changePositonByPositionType = (positionType, defaultPadding = this.defaultPadding) => {
        this.positionType = positionType;
        this.defaultPadding = defaultPadding;
        const w = this.newFramework.width;
        const h = this.newFramework.height;
        const [x, y] = initlayerPositionByCanvasScreenType({
            width: w,
            height: h,
            canvasDraw: this.parent,
            positionType,
            defaultPadding,
            screenType: this.parent?.canvasType,
            newFramework: this.newFramework,
        });
        this.newFramework.updateXYAngle({
            width: w,
            height: h,
            center: [x + w / 2, y + h / 2],
            angle: degreeToRotate(this.angle),
        });
        this.parent?.getLayerChangeAttributeCallBack();
    };
    /**
     * 全屏， 半屏，居中
     */
    setLayerPosition(layerPositionType) {
        this.angle = 0;
        this.layerPositionType = layerPositionType;
        let w = this.newFramework.width;
        let h = this.newFramework.height;
        const canvasW = this.parent?.canvas.width;
        const canvasH = this.parent?.canvas.height;
        // 默认 居中是 x y点
        let posXY = this.parent?.setLayerToCanvasCenter(w, h);
        switch (layerPositionType) {
            case 'fullScreen':
                w = canvasW;
                h = canvasH;
                posXY = [0, 0];
                break;
            case 'halfScreen':
                w = canvasW / 2;
                h = (w * this.baseH) / this.baseW;
                const x = this.parent?.center[0] - w / 2;
                const y = this.parent?.center[1] - h / 2;
                posXY = [x, y];
                break;
            case 'horizontalCenter':
                const x1 = (canvasW - w) / 2;
                posXY = [x1, this.newFramework.point[0][1]];
                break;
            case 'verticalCenter':
                const y2 = (canvasH - h) / 2;
                posXY = [this.newFramework.point[0][0], y2];
                break;
            case 'centerFullWidth':
                w = canvasW;
                h = this.baseH;
                posXY = this.parent?.setLayerToCanvasCenter(w, h);
                break;
            default:
                // w = this.baseW;
                // h = this.baseH;
                posXY = this.parent?.setLayerToCanvasCenter(w, h);
                break;
        }
        this.newFramework.updateXYAngle({
            width: w,
            height: h,
            center: [posXY[0] + w / 2, posXY[1] + h / 2],
            angle: degreeToRotate(this.angle),
        });
        this.parent?.getLayerChangeAttributeCallBack();
    }
    bindFun() {
        if (this.video) {
            this.video.addEventListener("play", () => this.play());
            this.video.addEventListener("pause", () => this.pause());
            this.video.addEventListener("ended", () => this.ended());
        }
    }
    clearVideo = () => {
        const videoId = this?.video?.id;
        const parentId = this?.parentId;
        if (videoId) {
            const ele = document.getElementById(videoId);
            const parentEle = document.getElementById(parentId);
            if (ele && parentEle) {
                parentEle.removeChild(ele);
            }
        }
    };
    play() {
        if (this.video) {
            this.video.play();
            // this.interval = setInterval(() => {
            //   this.drawVideo(ctx)
            //  }, 16)
        }
    }
    pause() {
        this.video?.pause();
        // clearInterval(this.interval)
        // this.interval= null;
    }
    ended() {
        // window.clearInterval(this.interval)
        // this.interval= null;
    }
    draw(ctx) {
        // this.parent.ctx.drawImage(this.video, this.x, this.y, this.width, this.height)
        const { video, newFramework } = this;
        const { ctx: context } = this.parent;
        const points = newFramework.point;
        const [c_x, c_y] = newFramework.center;
        context.save();
        context.translate(c_x, c_y);
        context.rotate(newFramework.angle);
        context.globalAlpha = this.opacity;
        context.drawImage(video, points[0][0] - c_x, points[0][1] - c_y, newFramework.width, newFramework.height);
        context.restore();
    }
}
