import { GifFrameDrawer } from "@/lib/CanvasDraw/utils/GifFrameDrawer";
export class GifFrameDrawerMange {
    urlTogifFrameDrawer;
    keyToUrl;
    keyMap = {};
    constructor() {
        this.urlTogifFrameDrawer = {};
        this.keyMap = {};
    }
    async setKeyMap(obj) {
        this.keyMap = obj || {};
        this.keyToUrl = {};
        Object.keys(this.keyMap).forEach(key => {
            this.keyToUrl[key] = this.keyMap[key].iconUrl;
        });
    }
    async addGifFrameDrawer(key) {
        const url = this.keyToUrl[key];
        if (!this.urlTogifFrameDrawer[key]) {
            if (this.keyToUrl[key]) {
                const img = new Image();
                img.src = url;
                img.setAttribute("crossOrigin", "anonymous");
                this.urlTogifFrameDrawer[url] = new GifFrameDrawer(img);
                await this.urlTogifFrameDrawer[url].loadGif();
            }
        }
        return this.urlTogifFrameDrawer[url];
    }
    getGifFrameDrawer(key) {
        return this.urlTogifFrameDrawer[this.keyToUrl[key]];
    }
    getKeyMap() {
        return this.keyMap;
    }
}
