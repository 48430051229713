import EventEmitter from "@/lib/Utils/EventEmitter";
/**
 * 一个历史记录管理器。
 */
export class MyHistory extends EventEmitter {
    items = [];
    currentIndex = -1;
    indexStatus = 'none';
    constructor() {
        super();
    }
    emitIndexStatus() {
        if (this.currentIndex === 0 && this.items.length === 1) {
            this.indexStatus = 'none';
        }
        else if (this.currentIndex === this.items.length - 1) {
            this.indexStatus = 'back';
        }
        else if (this.currentIndex === 0) {
            this.indexStatus = 'forward';
        }
        else {
            this.indexStatus = 'other';
        }
        this.emit('indexStatus', this.indexStatus);
    }
    /**
     * 插入新的历史记录，并删除当前位置之后的所有记录。
     * @param item 要插入的历史数据
     */
    insert(item) {
        // 如果当前索引不在数组末尾，删除当前索引之后的所有元素
        if (this.currentIndex < this.items.length - 1) {
            this.items.splice(this.currentIndex + 1);
        }
        // console.log('insert', item)
        // 添加新的项目并更新索引
        this.items.push(item);
        this.currentIndex++;
        this.emitIndexStatus();
    }
    /**
     * 回退到上一个历史记录。
     * @returns 上一个历史数据或者undefined如果没有更多历史数据。
     */
    back() {
        if (this.currentIndex >= 0) {
            this.currentIndex--;
            this.emitIndexStatus();
            return this.items[this.currentIndex];
        }
        return undefined;
    }
    /**
     * 前进到下一个历史记录。
     * @returns 下一个历史数据或者undefined如果没有更多历史数据。
     */
    forward() {
        // console.log('this.items',this.items)
        if (this.currentIndex < this.items.length - 1) {
            this.currentIndex++;
            this.emitIndexStatus();
            return this.items[this.currentIndex];
        }
        return undefined;
    }
    /**
     * 获取当前历史数据。
     * @returns 当前历史数据或者undefined如果没有数据。
     */
    getCurrent() {
        if (this.currentIndex >= 0 && this.currentIndex < this.items.length) {
            return this.items[this.currentIndex];
        }
        return undefined;
    }
}
