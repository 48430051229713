export function formatSeconds(frame, fps = 30) {
    // 计算分钟和秒
    const seconds = Math.floor(frame / fps);
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    // 将分钟和秒转换为两位数格式
    const formattedMins = mins.toString().padStart(2, "0");
    const formattedSecs = secs.toString().padStart(2, "0");
    // 返回格式化的时间字符串
    return `${formattedMins}:${formattedSecs}`;
}
export function formatFrame(frame, fps = 30) {
    const frames = frame % fps;
    // 当帧数对应于整数秒时，仅返回分钟和秒
    if (frames === 0) {
        return formatSeconds(frame, fps);
    }
    // 否则，返回秒内的具体帧数
    return `${frames}f`;
}
export function framesToTimecode(totalFrames, fps = 30) {
    // 计算总秒数
    const totalSeconds = totalFrames / fps;
    // 计算小时、分钟和秒
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    const seconds = Math.floor(totalSeconds - hours * 3600 - minutes * 60);
    // 计算剩余帧数
    const frames = Math.floor(totalFrames % fps);
    // 格式化输出，确保每个部分都至少有两位数字
    const formattedTime = [
        hours.toString().padStart(2, "0"),
        minutes.toString().padStart(2, "0"),
        seconds.toString().padStart(2, "0"),
        frames.toString().padStart(2, "0"),
    ].join(":");
    return formattedTime;
}
export function getTimeScaleType(minFramePerMark, fps = 30) {
    if (minFramePerMark < fps) {
        return "frames";
    }
    else {
        return "seconds";
    }
}
export function getFormatFrameF(timeScaleType) {
    switch (timeScaleType) {
        case "frames":
            return formatFrame;
        case "seconds":
            return formatSeconds;
    }
}
export function findDivisors(num) {
    let divisors = [];
    for (let i = 1; i <= num; i++) {
        if (num % i === 0) {
            divisors.push(i);
        }
    }
    return divisors;
}
export function findIntervalAdjusted(number, intervals) {
    // 确保区间数组是升序的
    intervals.sort((a, b) => a - b);
    const result = {
        interval: [0, 0], // 区间 [a, b] a <= number <= b
        positionPoint: number,
    };
    // 如果数字小于数组中的第一个元素
    if (number < intervals[0]) {
        result.interval = [number, intervals[0]];
    }
    // 如果数字大于数组中的最后一个元素
    if (number > intervals[intervals.length - 1]) {
        result.interval = [intervals[intervals.length - 1], number];
    }
    for (let i = 0; i < intervals.length - 1; i++) {
        if (number >= intervals[i] && number < intervals[i + 1]) {
            result.interval = [intervals[i], intervals[i + 1]];
            result.positionPoint = (intervals[i + 1] - intervals[i]) / 2 - (intervals[i + 1] - number) / 2 >= 0 ? intervals[i] : intervals[i + 1];
        }
    }
    return result;
}
export function doItemsOverlap(item1, item2) {
    if (item1 == item2)
        return false;
    const item1EndTime = item1.startTime + item1.time;
    const item2EndTime = item2.startTime + item2.time;
    const item1StartsInItem2 = item1.startTime >= item2.startTime && item1.startTime < item2EndTime;
    const item2StartsInItem1 = item2.startTime >= item1.startTime && item2.startTime < item1EndTime;
    return item1StartsInItem2 || item2StartsInItem1;
}
export const EVENT_MAP = {
    delete: "DELETE_EVENT",
    add: "ADD_EVENT",
    select: "SELECT_EVEN",
    update: "UPDATE_EVENT",
    onplay: "ON_PLAY",
    onpause: "ON_PAUSE",
    onback: "ON_BACK",
    onforward: "ON_FORWARD",
    onTimelineItemChange: "ON_TIMELINE_ITEM_CHANGE",
    onCaptionSwitch: 'ON_CAPTION_SWITCH',
};
