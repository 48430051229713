import Play from '@/assets/images/timeline/Play.svg';
import Pause from '@/assets/images/timeline/Pause.svg';
import Plus from '@/assets/images/timeline/Plus.svg';
import Minus from '@/assets/images/timeline/Minus.svg';
import QuanLan from '@/assets/images/timeline/QuanLan.svg';
import DirectionLeft from '@/assets/images/timeline/directionLeftNone.svg';
import DirectionRight from '@/assets/images/timeline/DirectionRightNone.svg';
// 26 行 暂时删除字幕按钮 
export const template = `
  <div id="timelineContainer">
    <div id="timelineControls">
      <div id="headBtns">
        <div id="direction">
           <img id="direction-left" style="width: 0; height: 0" src="${DirectionLeft}" title="撤销">
          <span id="direction-left-text" style="width: 0; height: 0"></span>
          <img id="direction-right" style="width: 0; height: 0" src="${DirectionRight}" title="前进">
        </div> 
        <div id="time-line-slider">
          <img id="time-line-slider-icon" class="play visible" src="${Play}">
          <img id="time-line-slider-icon" class="pause hide" src="${Pause}">
          <div id="time-line-slider-container"></div>
        </div>
        <div id="scale-slider">
          <div id="time-line-slider-times">00:00:00:00/00:00:00:00</div>
          <div id="time-line-slider-switch-box">
            <span id="time-line-slider-switch-text">字幕开关</span>
            <input id="customSwitch" type="checkbox"  value="checked" checked />
            <label for="customSwitch" id="time-line-slider-switch"></label>
          </div>
          <img id="scale-slider-quanlan" src="${QuanLan}" title="一键全览">
          <img id="scale-slider-minus" src="${Minus}">
          <div id="scale-slider-container"></div>
          <img id="scale-slider-plus" src="${Plus}">
        </div>
      </div>
    </div>
    <div id="canvasContainer">
      <div id="timelineRowIcons">
      </div>
      <div id="scrollbarContainer">
        <div id="scrollbar"></div>
      </div>
      <canvas id="timelineCanvas"></canvas>
    </div>
  </div>
`;
// export const template = `
//   <div id="timelineContainer">
//     <div id="timelineControls">
//       <div id="headBtns">
//         <div id="direction">
//           <img id="direction-left" src="${DirectionLeft}" title="后退">
//           <img id="direction-right" src="${DirectionRight}" title="前进">
//         </div>
//         <div id="time-line-slider">
//           <img id="time-line-slider-icon" class="play visible" src="${Play}">
//           <img id="time-line-slider-icon" class="pause hide" src="${Pause}">
//           <div id="time-line-slider-container"></div>
//           <div id="time-line-slider-times">00:00:00:00/00:00:00:00</div>
//         </div>
//         <div id="scale-slider">
//           <img id="scale-slider-quanlan" src="${QuanLan}" title="一键全览">
//           <img id="scale-slider-minus" src="${Minus}">
//           <div id="scale-slider-container"></div>
//           <img id="scale-slider-plus" src="${Plus}">
//         </div>
//       </div>
//     </div>
//     <div id="canvasContainer">
//       <div id="timelineRowIcons">
//       </div>
//       <div id="scrollbarContainer">
//         <div id="scrollbar"></div>
//       </div>
//       <canvas id="timelineCanvas"></canvas>
//     </div>
//   </div>
// `;
