// 背景图的绘制
export class BCIDraw {
    bcImgUrl;
    bcImg;
    imageLoadStaus; //杜： 处理图片太大时，image图片未加载完，标签未生成 而执行 drawImager，导致报错问题
    constructor() {
        this.bcImg = new Image();
        this.bcImg.setAttribute("crossOrigin", "anonymous");
        this.bcImgUrl = "";
        this.imageLoadStaus = 'none';
    }
    setBCImgUrl(url) {
        this.bcImgUrl = url;
        this.bcImg.src = this.bcImgUrl;
        this.imageLoadStaus = 'loadding';
        this.bcImg.onload = () => {
            this.imageLoadStaus = 'finish';
        };
        this.bcImg.onerror = () => {
            this.imageLoadStaus = 'error';
        };
    }
    clearBCImg() {
        this.bcImg.src = "";
        this.bcImgUrl = "";
    }
    draw(ctx, canvasType) {
        if (this.imageLoadStaus !== 'finish')
            return;
        const canvas = ctx.canvas;
        switch (canvasType) {
            case "16:9":
                ctx?.drawImage(this.bcImg, 0, 0, canvas.width, canvas.height);
                break;
            case "9:16":
                // 图片撑满整个画布，js计算图片的宽高比例来撑满画布
                const imgWidth = this.bcImg.width;
                const imgHeight = this.bcImg.height;
                const imgScale = imgWidth / imgHeight;
                const canvasScale = canvas.width / canvas.height;
                if (imgScale < canvasScale) {
                    ctx?.drawImage(this.bcImg, 0, (canvas.height - canvas.width / imgScale) / 2, canvas.width, canvas.width / imgScale);
                }
                else {
                    ctx?.drawImage(this.bcImg, (canvas.width - canvas.height * imgScale) / 2, 0, canvas.height * imgScale, canvas.height);
                }
                break;
            default:
                ctx?.drawImage(this.bcImg, 0, 0, canvas.width, canvas.height);
                break;
        }
    }
}
